import { createAction, createSelector, createSlice } from "@reduxjs/toolkit";
import {
  addImageType,
  addSubCategory,
  createType,
  delType,
  editType,
  loadTypes,
  loadbyNavbar,
} from "../../../http/typeApi";

// action/reducer/dispatch('forward')
const initialState = {
  status: "idle",
  error: null,
  list: [],
  selectedId: null,
  selectedName: null,
  selectedUrl: null,
  // subcategories: {},
};

const typeSlice = createSlice({
  name: "@@type",
  initialState,
  reducers: {
    setSelectedId: (state, action) => {
      const { id, name, imageUrl } = action.payload;
      state.selectedId = id;
      state.selectedName = name;
      state.selectedUrl = imageUrl;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createType.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createType.rejected, (state, action) => {
        state.status = "rejected";
        if (action.payload) {
          state.error = action.payload; // Извлекаем сообщение об ошибке из тела ответа
        } else {
          state.error = "Какая-то ошибка на сервере"; // Если нет сообщения об ошибке, то выводим стандартное сообщение
        }
      })

      .addCase(createType.fulfilled, (state, action) => {
        state.status = "received";
        state.error = null;
        state.list.push(action.payload.data);
      })
      .addCase(loadbyNavbar.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadbyNavbar.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadbyNavbar.fulfilled, (state, action) => {
        state.status = "received";
        state.error = null;
        state.list = action.payload.data;
      })
      .addCase(addImageType.fulfilled, (state, action) => {
        const { imageUrl, id } = action.payload;

        const updateImageUrlRecursively = (categoryList) => {
          const categoryId = parseInt(id); // Преобразование строки в число

          for (const category of categoryList) {
            if (category.id === categoryId) {
              // Используем преобразованное значение
              category.imageUrl = imageUrl;
              break;
            }

            if (category.children && category.children.length > 0) {
              updateImageUrlRecursively(category.children);
            }
          }
        };

        updateImageUrlRecursively(state.list);
        state.selectedUrl = imageUrl;
      })

      .addCase(delType.fulfilled, (state, action) => {
        state.status = "received";

        const deletedCategoryId = action.payload;

        const deleteCategoryRecursively = (categoryList) => {
          for (let i = 0; i < categoryList.length; i++) {
            const category = categoryList[i];

            if (category.id === deletedCategoryId) {
              categoryList.splice(i, 1); // Удаляем категорию из списка
              break; // После удаления категории можно прервать цикл
            }

            if (category.children && category.children.length > 0) {
              deleteCategoryRecursively(category.children);
            }
          }
        };

        deleteCategoryRecursively(state.list);
      })

      .addCase(editType.fulfilled, (state, action) => {
        state.status = "received";

        const updatedCategory = action.payload;

        const updateCategoryRecursively = (categoryList) => {
          for (let i = 0; i < categoryList.length; i++) {
            const category = categoryList[i];

            if (category.id === updatedCategory.id) {
              category.name = updatedCategory.name;
              category.url = updatedCategory.url;
              break; // После обновления категории можно прервать цикл
            }

            if (category.children && category.children.length > 0) {
              updateCategoryRecursively(category.children);
            }
          }
        };

        updateCategoryRecursively(state.list);
      })
      .addCase(addSubCategory.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addSubCategory.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(addSubCategory.fulfilled, (state, action) => {
        state.status = "received";
        const { parentId, ...subcategory } = action.payload.data;

        const findCategory = (categories, targetId) => {
          for (const category of categories) {
            if (category.id === targetId) {
              if (!category.children) {
                category.children = [];
              }
              category.children.push(subcategory);
              return true;
            }
            if (
              category.children &&
              findCategory(category.children, targetId)
            ) {
              return true;
            }
          }
          return false;
        };

        findCategory(state.list, parentId);
      });
  },
});

const selectTypeState = (state) => state.type;

export const selectTypeById = createSelector(
  [selectTypeState, (_, typeId) => typeId],
  (typeState, typeId) => {
    if (!typeId) return null; // Добавьте эту строку

    const type = typeState.list.find((type) => type.id === typeId);
    // return type ? type.children : null;
    return type && type.children?.length > 0 ? type.children : null;
  }
);

export const { setSelectedId } = typeSlice.actions;
export const selectSelectedId = (state) => state.type.selectedId;
export const selectSelectedName = (state) => state.type.selectedName;
export const selectSelectedUrl = (state) => state.type.selectedUrl;
export const selectAllTypes = (state) => state.type.list;
export const selectTypeStatus = (state) => state.type.status;
export const selectTypeError = (state) => state.type.error;

// export const selectTypeById = createSelector(
//   selectAllTypes,
//   (_, name) => name,
//   (types, name) => {
//     const findTypeByIdRecursive = (types, name) => {
//       for (const type of types) {
//         if (type.name === name) {
//           return type.id;
//         }

//         if (type.subcategories && type.subcategories.length > 0) {
//           const foundId = findTypeByIdRecursive(type.subcategories, name);
//           if (foundId) {
//             return foundId;
//           }
//         }
//       }

//       return null;
//     };

//     return findTypeByIdRecursive(types, name);
//   }
// );

export const typeReducer = typeSlice.reducer;
