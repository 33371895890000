import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const loadCities = createAsyncThunk(
  "@@cities/load-cities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/cities"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
