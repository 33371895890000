import React, { useEffect, useRef, useState } from "react";
import stl from "./content.module.scss";
import { ReactComponent as SvgImage } from "./svg/image.svg";

// import Modal from '../../../shared/ui/modal/Modal';
// import ContentAdvanced from './advanced/ContentAdvanced';
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { addImageType, loadTypes } from "../../../app/http/typeApi";
import { useLocation } from "react-router-dom";
import {
  createDevice,
  delDevice,
  loadDevices,
  loadDevicesToType,
} from "../../../app/http/deviceApi";
import ContentAdvanced from "./advanced/ContentAdvanced";
import Modal from "../../../shared/ui/modal/Modal";
import { selectAllDevices } from "../../../app/redux/store/deviceStore/deviceSlice";
import Yon from "../../../shared/ui/yesorno/Yon";
import Device from "./components/Device";
import { usePageName } from "./hooks/usePageName";
import { resetImages } from "../../../app/redux/store/imageSlice/imageSlice";
import { loadimagesId, tempImagesClear } from "../../../app/http/imageApi";
import PlusSubCategory from "../../../shared/ui/plusSubCategory/PlusSubCategory";
import {
  selectSelectedId,
  selectSelectedName,
  selectSelectedUrl,
  setSelectedId,
} from "../../../app/redux/store/typeStore/typeSlice";
import Slider from "../slider/Slider";
import Options from "../options/Options";
import WorkToXLS from "../../../features/workToXls/WorkToXLS";
import { loadPrice } from "../../../app/http/priceApi";
import Prices from "../../../features/prices/Prices";
import WorkToXLSMass from "../../../features/workToXls/WorkToXLSMass";
import { loadOptionsSelectDevice } from "../../../app/http/optionSelectApi";
import Galery from "../../../widgets/Galery/Galery";
import ImageCrop from "../../../features/imageCrop/ImageCrop";

const Content = () => {
  // const { id } = useParams();
  const dispatch = useDispatch();
  // const devices = useSelector(selectAllDevices);
  // console.log(devices);
  const selectedId = useSelector(selectSelectedId);
  const options = useSelector((state) => state.optionsSelect.list);
  // console.log(selectedId, "выбранная категория");
  const selectedName = useSelector(selectSelectedName);
  const selectedUrl = useSelector(selectSelectedUrl);
  // const loadedPrices = useSelector((state) => state.price.loadedPrices);
  // заменить три const на объект с тремя полями
  // console.log(selectedUrl, "нам нужно");

  //state
  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState(null);

  // const [modalType, setModalType] = useState(null);
  const [goods, setGoods] = useState(null);
  const [delId, setDelID] = useState(null);
  const [editId, setEditID] = useState(null);
  // const [uploadedImage, setUploadedImage] = useState(null);
  // const { name, id } = usePageName();
  // console.log(id, "id категории");
  const devices = useSelector(selectAllDevices);
  // console.log(devices, "!!!!!!!!!!!!!!!!!!!!!!!DEVICES");
  const text =
    "Внимание, будут удалены все изображения, которые связаны с товаром";

  //Изображение для категории
  const fileInputRef = useRef(null);
  const handleSvgPlusClick = () => {
    setModalType("imageCategory");
    setModalActive(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    dispatch(addImageType({ formData, id: selectedId }));
  };

  const newOneTovar = () => {
    setModalType("one");
    // dispatch(resetImages());
    // dispatch(tempImagesClear());
    setGoods(null);
    setModalActive(true);
  };
  const newMass = () => {
    setModalType("mass");
    // dispatch(resetImages());
    // dispatch(tempImagesClear());
    setGoods(null);
    setModalActive(true);
  };
  const updateMass = () => {
    setModalType("massupdate");
    // dispatch(resetImages());
    // dispatch(tempImagesClear());
    setGoods(null);
    setModalActive(true);
  };
  const editClick = (device, id) => {
    setModalType("edit");
    setGoods(device);
    setEditID(id);
    setModalActive(true);
    dispatch(loadimagesId(id));
  };
  const imageClick = (id) => {
    setModalType("image");
    // setGoods(device);
    setEditID(id);
    setModalActive(true);
    dispatch(loadimagesId(id));
  };

  const isAuth = useSelector((state) => state.isAuth);

  const predelClick = (id) => {
    setModalActive(true);
    // setDelID(id);
    setDelID(id);
  };
  const deleteClick = (data) => {
    dispatch(delDevice(data));
    setDelID(null);
  };

  // useEffect(() => {
  //   if (modalActive === false) dispatch(tempImagesClear());
  //   console.log("изображения очищены");
  // }, [modalActive]);
  useEffect(() => {
    dispatch(loadOptionsSelectDevice());
  }, [options]);
  // useEffect(() => {
  //   if (selectedId) {
  //     dispatch(loadPrice(selectedId)); // Вызываем загрузку прайса
  //   }
  // }, [selectedId]);

  return (
    <>
      <Modal visible={modalActive} setVisible={setModalActive}>
        {delId ? (
          <Yon
            del={deleteClick}
            text={text}
            id={delId}
            setVisible={setModalActive}
          />
        ) : modalType === "one" ? (
          <ContentAdvanced
            // type={"one"}
            id={selectedId}
            setVisible={setModalActive}
          />
        ) : modalType === "mass" ? (
          <WorkToXLSMass
            id={selectedId}
            type={modalType}
            setVisible={setModalActive}
          />
        ) : modalType === "massupdate" ? (
          <WorkToXLSMass
            id={selectedId}
            type={modalType}
            setVisible={setModalActive}
          />
        ) : modalType === "imageCategory" ? (
          <ImageCrop id={selectedId} setVisible={setModalActive} />
        ) : modalType === "edit" ? (
          <ContentAdvanced
            type={"edit"}
            id={selectedId}
            goods={goods}
            editId={editId}
            setVisible={setModalActive}
          />
        ) : modalType === "image" ? (
          <Galery
            editId={editId}
            //  setVisible={setModalActive}
          />
        ) : null}
      </Modal>

      <div className={stl.wrapper}>
        {selectedId ? (
          <>
            <h1>Это раздел для {selectedName}</h1>
            {/* <div className={stl.addbtn} onClick={newMass}>
              + массовый товар XLS
            </div> */}
            {devices.some((device) => device.articul !== null) ? (
              <div className={stl.addbtn} onClick={updateMass}>
                обновление товар через XLS
              </div>
            ) : (
              <div className={stl.addbtn} onClick={newMass}>
                + добавить много товаров
              </div>
            )}{" "}
            {/* <div className={stl.addbtn} onClick={newMass}>
              + массовый товар XLS
            </div> */}
            {selectedUrl !== null ? (
              <>
                <img
                  className={stl.typeimg}
                  src={
                    process.env.REACT_APP_API_URL + "category/" + selectedUrl
                  }
                  alt=""
                  onClick={handleSvgPlusClick}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </>
            ) : (
              <>
                <h4 className={stl.h4}>Добавить изображение категории</h4>
                <SvgImage
                  className={stl.svg_plus}
                  onClick={handleSvgPlusClick}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </>
            )}
            <PlusSubCategory parentId={selectedId} />
            <div className={stl.addbtn} onClick={newOneTovar}>
              +товар
            </div>
            {devices &&
              devices.length > 0 &&
              devices
                .slice() // Создаем копию массива, чтобы избежать изменения оригинального массива
                .sort((a, b) => a.price - b.price) // Сортируем по полю 'price'
                .map((device) => (
                  <Device
                    key={device.id}
                    {...device}
                    predelClick={predelClick}
                    editClick={editClick}
                    imageClick={imageClick}
                  />
                ))}
          </>
        ) : (
          <h1>&lt;&lt; Выберите категорию</h1>
        )}
      </div>
    </>
  );
};

export default Content;
