// CardGalleryDesktop.js
import React, { useState, useEffect } from "react";
import styles from "./cardGalleryDesktop.module.scss";

const CardGalleryDesktop = ({ images }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleMouseOver = (index) => {
    setImageUrl(
      process.env.REACT_APP_API_URL +
        "images/products/" +
        images[index]?.desktopCard
    );
    setCurrentIndex(index);
  };

  const handleMouseOut = () => {
    setImageUrl(
      process.env.REACT_APP_API_URL +
        "images/products/" +
        images[0]?.desktopCard
    );
    setCurrentIndex(0);
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setImageUrl(
        process.env.REACT_APP_API_URL +
          "images/products/" +
          images[0]?.desktopCard
      );
    }
  }, [images]);

  return (
    <>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          height: "100%",
        }}>
        {images &&
          images.length > 0 &&
          images.map((image, index) => (
            <div
              key={index}
              style={{
                width: `${100 / images.length}%`,
                height: "100%",
                opacity: "100%",
              }}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            />
          ))}
      </div>
      <div className={styles.cursor}>
        {images &&
          images.map((image, index) => (
            <div
              key={index}
              style={{
                width: `${100 / images.length}%`,
                height: "100%",
                borderRadius: "7px",
                opacity: currentIndex === index ? "100%" : "0%",
                backgroundColor:
                  index === currentIndex ? "rgba(100, 100, 100, 0.5)" : "",
              }}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            />
          ))}
      </div>
    </>
  );
};

export default CardGalleryDesktop;
