import React, { useEffect, useState } from "react";
import styles from "./cardGallery.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";

const CardGallery = ({ images }) => {
  const [imageUrl, setImageUrl] = useState("");
  const isMobile = useMediaQuery("(max-width:768px)");
  const [isAnimating, setIsAnimating] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseOver = (index) => {
    setImageUrl(
      process.env.REACT_APP_API_URL +
        "images/products/" +
        images[index]?.desktopCard
    );
    setCurrentIndex(index);
  };

  const handleMouseOut = () => {
    setImageUrl(
      process.env.REACT_APP_API_URL +
        "images/products/" +
        images[0]?.desktopCard
    );
    setCurrentIndex(0);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const diffX = e.touches[0].clientX - startX;
    if (diffX > 50) {
      handlePrev();
      setIsDragging(false);
    } else if (diffX < -50) {
      handleNext();
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handlePrev = () => {
    const newIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
    setImageUrl(
      process.env.REACT_APP_API_URL +
        "images/products/" +
        images[newIndex]?.desktopCard
    );
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = currentIndex < images.length - 1 ? currentIndex + 1 : 0;
    setImageUrl(
      process.env.REACT_APP_API_URL +
        "images/products/" +
        images[newIndex]?.desktopCard
    );
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    if (images && images.length > 0) {
      setImageUrl(
        process.env.REACT_APP_API_URL +
          "images/products/" +
          images[0]?.desktopCard
      );
    }
  }, [images]);

  return (
    <>
      <div
        className={`${styles.img} ${isMobile ? styles.slide : ""}`}
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          height: "100%",
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        {images &&
          images.length > 0 &&
          images.map((image, index) => (
            <div
              key={index}
              style={{
                width: `${100 / images.length}%`,
                height: "100%",
                opacity: "100%",
              }}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            />
          ))}
      </div>
      <div className={styles.cursor}>
        {images &&
          images.map((image, index) => (
            <div
              key={index}
              style={{
                width: `${100 / images.length}%`,
                height: "100%",
                borderRadius: "7px",
                opacity: currentIndex === index ? "100%" : "0%",
                backgroundColor:
                  index === currentIndex ? "rgba(100, 100, 100, 0.5)" : "",
              }}
              onMouseOver={() => handleMouseOver(index)}
              onMouseOut={handleMouseOut}
            />
          ))}
      </div>
    </>
  );
};

export default CardGallery;
