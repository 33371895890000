import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./byButton.module.scss";
import { IoBasket } from "react-icons/io5";

import {
  addItemToCart,
  removeItemFromCart,
  selectQntByItem,
} from "../../../app/redux/cartStore/cartSlice";
// ... остальные импорты

const BuyButton = ({ id, name, dimensions, image, price }) => {
  // console.log(id, "что у нас в IDDDDDDD");
  const dispatch = useDispatch();
  const totalItems = useSelector((state) => selectQntByItem(state, id));
  const totalPrice = useSelector((state) => state.totalPrice);

  const handleBuyClick = () => {
    // dispatch(addItemToCart({ id, name, price, image, qnt, lngt }));
    dispatch(
      // addItemToCart({
      //   id: totalPrice.idProduct,
      //   name,
      //   price: totalPrice.basedPrice, //поправить, нужно sumPrice
      //   image,
      //   options: totalPrice.optionsFromBasket,
      // })
      addItemToCart({
        id,
        name,
        price,
        image,
        dimensions,
        // pricechecked,
        // city,
        // options: totalPrice.optionsFromBasket || null,
        // dimensions,
      })
    );
  };
  const handleMinusClick = () => {
    dispatch(removeItemFromCart({ id }));
  };
  const renderButton = () => {
    if (totalItems > 0) {
      return (
        <div className={styles.card}>
          <IoBasket /> <p className={styles.p}>В корзине {totalItems}</p>
          <div className={styles.plus}>
            <button onClick={handleBuyClick}>+</button>
            <button onClick={handleMinusClick}>-</button>
          </div>
        </div>
      );
    } else {
      return (
        <div onClick={handleBuyClick} className={styles.card}>
          <IoBasket /> <p className={styles.p}>В корзину</p>
        </div>
      );
    }
  };

  return <div className={styles.button}>{renderButton()}</div>;
};

export default BuyButton;
