import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./userStore/authSlice";
import axios from "axios";
import * as http from "../../http/index.js";
import { typeReducer } from "./typeStore/typeSlice";
import { userReducer } from "./userStore/userSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { deviceReducer } from "./deviceStore/deviceSlice";
import { galleryReducer } from "./imageSlice/imageSlice";
import { cartReducer } from "../cartStore/cartSlice";
import sliderSlice from "./sliderStore/sliderSlice";
import citySlice from "./cityStore/citySlice";
import optionSlice from "./optionStore/optionSlice";
import OptionSelectSlice from "./optionSelectStore/optionSelectSlice";
import priceSlice from "./priceStore/priceSlice";
import optionListSlice from "./optionStore/optionListSlice.js";
import totalPriceSlice from "./totalPriceStore/totalPriceSlice.js";
import ImageConfigureSlice from "./ImageConfigure/ImageConfigureSlice.js";
import orderSlice from "./orderSlice/orderSlice.js";
import { deliveryReducer } from "./deliveryStore/deliverySlice.js";

const rootReducer = combineReducers({
  isAuth: authReducer,
  type: typeReducer,
  device: deviceReducer,
  user: userReducer,
  galery: galleryReducer,
  cart: cartReducer,
  slider: sliderSlice,
  city: citySlice,
  option: optionSlice,
  optionList: optionListSlice,
  price: priceSlice,
  optionsSelect: OptionSelectSlice,
  totalPrice: totalPriceSlice,
  imageOption: ImageConfigureSlice,
  order: orderSlice,
  delivery: deliveryReducer,
});

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["isAuth"],
  whitelist: ["isAuth", "cart"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({
      thunk: {
        extraArgument: {
          client: axios,
          http,
        },
      },
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
