import React, { useEffect, useState } from "react";
import stl from "./left.module.scss";
import { ReactComponent as SvgAdd } from "./add.svg";
import { ReactComponent as SvgDel } from "./del.svg";
import { ReactComponent as SvgEdit } from "./edit.svg";
import { ReactComponent as SvgConfirm } from "./confirm.svg";
import Modal from "../../../shared/ui/modal/Modal";
import {
  createType,
  delType,
  downType,
  editType,
  loadTypes,
  loadbyNavbar,
  upType,
} from "../../../app/http/typeApi";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllTypes,
  selectTypeError,
  selectTypeStatus,
  setSelectedId,
} from "../../../app/redux/store/typeStore/typeSlice";
import { Link, useNavigate } from "react-router-dom";
import { ADMIN_ROUTE } from "../../../app/router/utils/consts";
import Yon from "../../../shared/ui/yesorno/Yon";
import {
  loadDevicesToType,
  loadDevicesToTypeAdmin,
} from "../../../app/http/deviceApi";
import { toggleSlider } from "../../../app/redux/store/sliderStore/sliderSlice";
import { toggleOption } from "../../../app/redux/store/optionStore/optionSlice";
import { toggleCities } from "../../../app/redux/store/cityStore/citySlice";
import {
  selectAllDevices,
  setDevicesNull,
} from "../../../app/redux/store/deviceStore/deviceSlice";
import MapComponent from "../../../features/YandexMaps/MapComponent";

const Left = () => {
  const type = useSelector(selectAllTypes);
  const devices = useSelector(selectAllDevices);
  const dispatch = useDispatch();
  const [modalActive, setModalActive] = useState(false);
  const [delId, setDelID] = useState("");
  const [data, setData] = useState("");
  const [edit, setEdit] = useState("");
  const [editId, setEditId] = useState("");
  const status = useSelector(selectTypeStatus);
  const error = useSelector(selectTypeError);
  const text =
    "Внимание, будут удалены все изображения и товары, которые связаны с категорией";
  const [categoryExpanded, setCategoryExpanded] = useState({});
  const [openedCategory, setOpenedCategory] = useState(null);

  console.log(delId, "!!!!!!!!!!!!!!!!!!!!!!!!!!devices");

  const toggleCategory = (categoryId) => {
    setCategoryExpanded((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  // const navigate = useNavigate();
  // create
  const handleSubmit = (e) => {
    if (data === "") {
      e.preventDefault();
      onBlur();
      alert("пусто");
    } else {
      e.preventDefault();
      dispatch(
        createType({
          name: data.toLowerCase(),
        })
      );
      setData("");
    }
  };
  // update
  const editSubmit = (id, edit) => {
    if (edit === "") {
      onBlur();
    } else {
      console.log(id, edit, "проверка админки");
      dispatch(
        editType({
          name: edit,
          id,
        })
      ).then((res) => {
        // console.log(res);
        // navigate(ADMIN_ROUTE + "/" + `${res.payload.url}`);
        // navigate(`${res.payload.url}`);
        setEditId("");
      });
    }
  };

  const editClick = (data) => {
    setEdit("");
    setEditId(data);
  };
  const onBlur = () => {
    setTimeout(() => {
      if (!document.activeElement.classList.contains(stl.inp)) {
        setEditId("");
      }
    }, 300);
    // setTimeout(() => setEditId(""), 300);
  };
  //delete
  const predelClick = (id) => {
    setModalActive(true);
    setDelID(id);
    console.log(delId, "delID!!!!!!!!!!!!!!!!!!!!!");
  };
  const deleteClick = async (data) => {
    // dispatch(delType(data)).then(() => navigate(ADMIN_ROUTE));
    // dispatch(delType(data));
    await dispatch(delType(data)); // Дожидаемся завершения upType
    dispatch(loadbyNavbar());
    if (devices.length > 0) {
      if (devices[0].typeId === delId) dispatch(setDevicesNull());
    }
  };

  // useEffect(() => {
  //   if (type) dispatch(selectAllTypes));
  // }, [type]);
  // console.log(type, "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");

  const renderCategory = (category, index, subl = 0) => {
    const isExpanded = categoryExpanded[category.id] || false;
    // const childrenCount = category.children?.length || 0;
    const childLength = subl - 1;

    const handleLinkClick = (e) => {
      e.stopPropagation();
      const categoryId = category.id;

      // Если категория уже открыта, снимаем выделение
      if (openedCategory === categoryId) {
        setOpenedCategory(null);
      } else {
        // В противном случае, устанавливаем категорию как открытую
        setOpenedCategory(categoryId);
      }
      toggleCategory(category.id);
      dispatch(loadDevicesToTypeAdmin(category.id));
      dispatch(
        setSelectedId({
          id: category.id,
          name: category.name,
          imageUrl: category.imageUrl,
          // child: category.children?.length,
        })
      ); // Устанавливаем выбранный id в Redux-состояние
    };

    const moveCategoryUp = async (categoryId) => {
      await dispatch(upType(categoryId)); // Дожидаемся завершения upType
      dispatch(loadbyNavbar()); // Затем загружаем новые данные
    };

    const moveCategoryDown = async (categoryId) => {
      await dispatch(downType(categoryId));
      dispatch(loadbyNavbar());
    };

    return (
      <li key={category.id} className={stl.listItem}>
        <div className={stl.categoryWrapper}>
          <Link
            to={`/admin/${category.url}`}
            // className={stl.btn}
            // className={`${stl.btn} ${isExpanded ? stl.expanded : ""}`}
            className={`
    ${stl.btn}
    ${category.id === openedCategory ? stl.expanded : ""}
  `}
            onClick={handleLinkClick}>
            {!(editId === category.id) ? (
              `${category.name}`
            ) : (
              <input
                type="text"
                className={stl.inp}
                defaultValue={category.name}
                onBlur={onBlur}
                autoFocus={true}
                onChange={(e) => setEdit(e.target.value)}
              />
            )}
            {editId === category.id ? (
              <SvgConfirm
                onClick={() => editSubmit(category.id, edit)}
                className={stl.svgconfirm}
              />
            ) : (
              <SvgEdit
                onClick={() => editClick(category.id)}
                className={stl.svgedt}
              />
            )}
            <SvgDel
              onClick={() => predelClick(category.id)}
              className={stl.svgdel}
            />
            {(type.length === 1 && childLength === -1) ||
            (type.length === 1 && index === 0 && childLength === -1) ||
            (index === 0 && childLength === 0) ? null : index === 0 ? (
              <button onClick={() => moveCategoryDown(category.id)}>↓</button>
            ) : (category.parentId === null && index === type.length - 1) ||
              index === childLength ? (
              <button onClick={() => moveCategoryUp(category.id)}>↑</button>
            ) : (
              <>
                <button onClick={() => moveCategoryUp(category.id)}>↑</button>
                <button onClick={() => moveCategoryDown(category.id)}>↓</button>
              </>
            )}
            {/* {index === currentIndex && (
              <button onClick={() => moveCategoryDown(category.id)}>
                вверх
              </button>
            )} */}
          </Link>

          {category.children && category.children.length > 0 && (
            <button
              className={stl.expandBtn}
              onClick={(e) => {
                e.stopPropagation();
                toggleCategory(category.id);
              }}>
              {isExpanded ? "-" : "+"}
            </button>
          )}
        </div>

        {category.children && category.children.length > 0 && isExpanded && (
          <ul className={stl.subcategoryList}>
            {category.children.map(
              (subcategory, subIndex) =>
                renderCategory(subcategory, subIndex, category.children?.length) // передаем index как parentIndex
            )}
          </ul>
        )}
      </li>
    );
  };

  return (
    <>
      <Modal visible={modalActive} setVisible={setModalActive}>
        <Yon
          text={text}
          del={deleteClick}
          id={delId}
          setVisible={setModalActive}
        />
      </Modal>
      <div className={stl.wrapper}>
        <ul className={stl.subcategoryList}>
          <Link to="slider" className={stl.btn}>
            Слайдер
          </Link>
          <Link to="options" className={stl.btn}>
            Опции
          </Link>
          <Link to="deliveries" className={stl.btn}>
            Доставка
          </Link>
          {/* <Link to="openstreetmap" className={stl.btn}>
            openstreetmap
          </Link> */}
          <Link to="orders" className={stl.btn}>
            Заказы
          </Link>
          {type.map((category, index) => renderCategory(category, index))}
        </ul>

        <form onSubmit={handleSubmit}>
          <input
            className={stl.inp_cat}
            type="text"
            value={data}
            onChange={(e) => setData(e.target.value)}
          />

          <button className={stl.add}>+ категория</button>
          {/* <SvgAdd className={stl.svgadd} /> */}
        </form>

        {error && <div>Ошибка {error}</div>}
      </div>
    </>
  );
};

export default Left;
