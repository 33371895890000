import React, { useCallback, useEffect, useState } from "react";
import stl from "./sliderFront.module.scss";
import { ReactComponent as SvgNext } from "./svg/next.svg";
import { ReactComponent as SvgPrev } from "./svg/prev.svg";
import { useDispatch, useSelector } from "react-redux";
import { loadSlides } from "../../../../app/http/slideApi";
import { selectAllSlider } from "../../../../app/redux/store/sliderStore/sliderSlice";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const SliderFront = () => {
  const dispatch = useDispatch();
  const imageload = useSelector(selectAllSlider);
  const isMobile = useMediaQuery("(max-width:768px)");
  // далее код для ручной смены фотографий мышкой или пальцем
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const diffX = e.clientX - startX;
    if (diffX > 50) {
      prevF();
      setIsDragging(false);
    } else if (diffX < -50) {
      nextF();
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const diffX = e.touches[0].clientX - startX;
    if (diffX > 50) {
      prevF();
      setIsDragging(false);
    } else if (diffX < -50) {
      nextF();
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };
  // конец кода

  //   console.log(imageload[0].imageUrl);

  const len = imageload.length;
  //   const [activePosition, setactiveSlide] = useState(0);
  const [activeImage, setActiveImage] = useState(0);
  const initialPosition = activeImage * 100;
  const [activePosition, setactiveSlide] = useState(-initialPosition);
  const [stopSlide, setstopSlide] = useState(null);

  const prevF = () => {
    activeImage > 0 ? setActiveImage(activeImage - 1) : setActiveImage(len - 1);
    if (activeImage === 0) {
      setactiveSlide(activePosition - (len - 1) * 100);
    } else {
      setactiveSlide(activePosition + 100);
    }
  };
  const nextF = useCallback(() => {
    activeImage < len - 1 ? setActiveImage(activeImage + 1) : setActiveImage(0);
    if (activeImage === len - 1) {
      setactiveSlide(activePosition + (len - 1) * 100);
    } else {
      setactiveSlide(activePosition - 100);
    }
  }, [activeImage, activePosition, len]);

  useEffect(() => {
    if (stopSlide === null && !isMobile) {
      const intervalId = setInterval(() => {
        nextF();
      }, 5000);
      console.log("автопрокрутка");
      return () => clearInterval(intervalId);
    } else {
      return;
    }
  }, [activeImage, nextF, stopSlide]);

  useEffect(() => {
    dispatch(loadSlides());
  }, [dispatch]);

  return (
    <div
      className={stl.wrapper}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      {!isMobile && (
        <div className={stl.box} style={{ left: "0px" }}>
          <SvgPrev className={stl.svg2} onClick={prevF} />
        </div>
      )}
      <div
        style={{ transform: `translateX(${activePosition}%)` }}
        className={stl.window}
        onMouseEnter={() => setstopSlide(1)}
        onMouseLeave={() => setstopSlide(null)}>
        {imageload.length > 0 ? (
          imageload.map((item, index) => (
            <div key={item.imageUrl} className={stl.imageContainer}>
              <img
                className={stl.image}
                src={
                  isMobile
                    ? process.env.REACT_APP_API_URL +
                      "/slider/" +
                      item.imageMobileSlider
                    : process.env.REACT_APP_API_URL + "/slider/" + item.imageUrl
                }
                alt=""
              />
              <Link to={item.link}>
                {" "}
                {item.title.trim() !== "" && (
                  <div className={stl.textBlock}>
                    <h2 style={{ textAlign: "center", color: "white" }}>
                      {item.title}
                    </h2>
                    {/* {item.link && (
                    <Link
                      to={item.link}
                      // target="_blank"
                      rel="noopener noreferrer"
                      className={stl.btnPay}>
                      Подробнее
                    </Link>
                  )} */}
                  </div>
                )}{" "}
                <p className={stl.about}>{item.text}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>загрузка</p>
        )}
      </div>
      {!isMobile && (
        <div className={stl.box} style={{ right: "0px" }}>
          <SvgNext className={stl.svg} onClick={nextF} />
        </div>
      )}
    </div>
  );
};

export default SliderFront;
