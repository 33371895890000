import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loadJson, loadZone } from "../../../../../app/http/delivery";

const FileUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    // Создаем FormData объект для передачи файла на сервер
    const formData = new FormData();
    formData.append("file", file);

    // Вызываем функцию loadJson с данными файла
    dispatch(loadJson(formData))
      .then((response) => {
        console.log("Zones updated successfully:", response);
        dispatch(loadZone());
      })
      .catch((error) => {
        console.error("Failed to update zones:", error);
      });
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default FileUpload;
