import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./byButton.module.scss";
import {
  addItemToCart,
  removeItemFromCart,
  selectQntByItem,
} from "../../../app/redux/cartStore/cartSlice";
import { IoBasket } from "react-icons/io5";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
// ... остальные импорты

const ByButtonItem = ({ id, name, image, price, dimensions, tarea }) => {
  const dispatch = useDispatch();
  const totalPrice = useSelector((state) => state.totalPrice);
  const totalItems = useSelector((state) =>
    selectQntByItem(state, id, totalPrice?.sumPrice)
  );
  // console.log(dimensions, "подсчет товара");

  const handleBuyClick = () => {
    // dispatch(addItemToCart({ id, name, price, image, qnt, lngt }));
    dispatch(
      addItemToCart({
        id: totalPrice.idProduct,
        name,
        tarea,
        price: totalPrice.sumPrice,
        image,
        options: totalPrice.optionsFromBasket,
        dimensions: totalPrice.dimensions,
      })
    );
  };
  const handleMinusClick = () => {
    dispatch(removeItemFromCart({ id, price }));
  };
  const renderButton = () => {
    if (totalItems > 0) {
      return (
        <div className={styles.card}>
          <CiCirclePlus className={styles.btn} onClick={handleBuyClick} />
          <IoBasket /> <p className={styles.p}>В корзине {totalItems} </p>
          <CiCircleMinus
            className={styles.btnminus}
            onClick={handleMinusClick}
          />
          {/* <button >+</button> */}
          {/* <button onClick={handleMinusClick}>-</button> */}
        </div>
      );
    } else {
      return (
        <div onClick={handleBuyClick} className={styles.card}>
          <IoBasket className={styles.p} />
          <p className={styles.p}>В корзину</p>
        </div>
      );
    }
  };

  return <div className={styles.button}>{renderButton()}</div>;
};

export default ByButtonItem;
