import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addOption } from "../../../app/http/optionApi";
import style from "./optionsModal.module.scss";
import { ReactComponent as SvgImage } from "./svg/image.svg";

const OptionModal = ({ setVisible }) => {
  //   const [optionName, setOptionName] = useState("");
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showLimitSquareInput, setShowLimitSquareInput] = useState(false);
  const [showColorOption, setShowColorOption] = useState(false);

  const [formData, setFormData] = useState({
    // img: [],
    name: "",
    tarea: "",
    about: "",
    priceChange: 0,
    color: "",
    // type: "",
    // optionalType: "",
    url: "",
    standalone: false,
    // limitSquare: 0,
  });

  const fileInputRef = useRef(null);
  const handleSvgPlusClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(file);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });

    if (name === "limitSquareCheck") {
      setShowLimitSquareInput(checked);
    }
  };
  const handleCheckboxChangeColor = (event) => {
    setShowColorOption(event.target.checked);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value, // Используйте [name] для динамического обновления состояния
    });
  };
  const handleSave = async (e) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();

    // Добавляем файл картинки в formData
    if (fileInputRef.current.files[0]) {
      formDataToSubmit.append("img", fileInputRef.current.files[0]);
    }

    // Добавляем введенные параметры в formData
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
    // console.log(formDataToSubmit, "проверка сохранения опции");

    dispatch(addOption(formDataToSubmit));
    setVisible(false);
  };
  //   console.log(optionName);

  return (
    <div className={style.wrapper}>
      <p>Опция</p>
      <h5>
        Подсказка: если создаёте цвета, то выбирайте тип кнопки: Радиокнопка
      </h5>

      <h4 className={style.h4}>Добавить изображение опции</h4>
      {selectedImage ? (
        <img src={selectedImage} className={style.option} alt="Загружено" />
      ) : (
        <SvgImage className={style.svg_plus} onClick={handleSvgPlusClick} />
      )}
      <div className={style.inputs}>
        {/* <p></p> */}
        <input
          type="text"
          name="name"
          placeholder="Название опции"
          onChange={handleChange}
        />
        <label>
          <input
            type="checkbox"
            name="standalone"
            checked={formData.standalone}
            onChange={handleCheckboxChange}
          />
          Самостоятельная опция( не цвет)
        </label>
        <label>
          <input
            type="checkbox"
            name="limitSquareCheck"
            // checked={formData.limitSquare}
            onChange={handleCheckboxChange}
          />
          Ограничение по высоте цветка для упаковки
        </label>
        <label>
          <input
            type="checkbox"
            name="limitSquareCheck"
            // checked={formData.limitSquare}
            onChange={handleCheckboxChangeColor}
          />
          Цвет упаковки
        </label>
        {showColorOption && (
          <div>
            <label htmlFor="colorPicker">Выберите цвет упаковки:</label>
            <input type="color" name="color" onChange={handleChange} />
          </div>
        )}
        {/* <p></p> */}
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />

        <input
          type="text"
          name="about"
          placeholder="Описание для админки"
          onChange={handleChange}
        />
        <input
          type="text"
          name="tarea"
          placeholder="Описание опции"
          onChange={handleChange}
        />

        {/* <input
          type="text"
          name="url"
          placeholder="ссылка на товар, если есть"
          onChange={handleChange}
        /> */}

        {/* <div> */}
        {/* <label htmlFor="typeSelect">Тип наценки:</label> */}
        {/* <select name="type" onChange={handleChange}>
            <option value="Нет">Нет</option>
            <option value="Процент">Процент</option>
            <option value="Сумма">Сумма</option>
          </select> */}
        <input
          type="number"
          name="priceChange"
          placeholder="Прайсовая наценка"
          onChange={handleChange}
        />
        {/* </div> */}

        <p></p>
        <label htmlFor="typeSelect">Тип кнопки (если нужна):</label>
        <select name="OptionalType" onChange={handleChange}>
          <option value="Нет">Нет</option>
          <option value="checkbox">Флажок</option>
          <option value="radio">Радиокнопка</option>
        </select>
        <p></p>
      </div>

      <button onClick={handleSave}>Сохранить</button>
    </div>
  );
};

export default OptionModal;
