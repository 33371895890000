import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const addPrice = createAsyncThunk(
  "@@price/create-price",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await $host.post(`api/price/${id}`, formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addPriceMass = createAsyncThunk(
  "@@price/create-price-mass",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await $host.post(`api/price/mass/${id}`, formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updatePriceMass = createAsyncThunk(
  "@@price/update-price-mass",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/price/mass/${id}`, formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadPrice = createAsyncThunk(
  "@@price/load-price",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/price/${id}`);
      console.log(response.data, "консоль!!!!!!!!!!!");
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
// export const loadPricemass = createAsyncThunk(
//   "@@price/load-price-mass",
//   async (id, { rejectWithValue }) => {
//     try {
//       const response = await $host.get(`api/price/mass/${id}`);
//       // console.log(response.data, "консоль!!!!!!!!!!!");

//       return response.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error.response.data.message);
//     }
//   }
// );

export const UniqueLoadWH = createAsyncThunk(
  "@@price/unique-widths-heights",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/price/uniquewh/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const UniqueLoadH = createAsyncThunk(
  "@@price/unique-only-heights",
  async ({ id, selectedWidth }, { rejectWithValue }) => {
    try {
      const response = await $host.post(`api/price/uniqueheight/${id}`, {
        selectedWidth,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
