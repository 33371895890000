import React from "react";
import style from "./orderby.module.scss";
import FormOrderBy from "../formOrderBy/FormOrderBy";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToCart,
  deleteItemFromCart,
  removeItemFromCart,
  selectAllItems,
  selectTotalPrice,
} from "../../app/redux/cartStore/cartSlice";
import MapComponent from "../../widgets/maps/MapComponent";
import OverPassTurbo from "../../widgets/maps/OverPassTurbo";

const OrderBy = ({ children }) => {
  const dispatch = useDispatch();
  const allItems = useSelector(selectAllItems);
  const allPrice = useSelector(selectTotalPrice);

  const handleAddToCart = (item) => {
    dispatch(addItemToCart(item));
  };

  const handleRemoveItemFromCart = (item) => {
    dispatch(removeItemFromCart(item));
  };
  const handleRemoveFromCart = (item) => {
    dispatch(deleteItemFromCart(item));
  };

  return (
    <>
      {/* <MapComponent /> */}
      {/* <OverPassTurbo /> */}
      <div className={style.header}>Оформление заказа</div>
      <div className={style.line}></div>
      <div className={style.wrapper}>
        <div className={style.productsWrapper}>
          {allItems.map((item) => {
            if (item.quantity > 0) {
              return (
                <div
                  key={item.id}
                  // className={style.wrapProduct}
                >
                  <div className={style.productName}>{item.name}</div>
                  <div key={item.name} className={style.product}>
                    <div>
                      <img
                        src={
                          process.env.REACT_APP_API_URL +
                          "images/products/" +
                          item.image[0].mini
                        }
                        className={style.img}
                        alt="sdf"
                      />
                    </div>
                    <div className={style.productDetails}>
                      <div>
                        <p className={style.p}>
                          Количество цветков: {item.dimensions.qnt} шт
                        </p>
                        {item.dimensions.height != 0 && (
                          <p className={style.p}>
                            Высота цветка: {item.dimensions.height} см
                          </p>
                        )}
                      </div>
                      <div>
                        <p className={style.p}>
                          Количество букетов: {item.quantity} шт
                        </p>
                        <div className={style.productPrice}>
                          Цена за единицу товара:{" "}
                          <span className={style.spanSmall}>{item.price}</span>{" "}
                          руб
                        </div>
                      </div>

                      <div className={style.btnArr}>
                        <div
                          className={style.btnPlus}
                          onClick={() => handleAddToCart(item)}>
                          +
                        </div>
                        <div
                          className={style.btnMinus}
                          onClick={() => handleRemoveItemFromCart(item)}>
                          -
                        </div>
                        <div
                          className={style.btnRemove}
                          onClick={() => handleRemoveFromCart(item)}>
                          Х
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
          {allItems && allItems?.length > 0 ? (
            <div className={style.allprice}>
              Итого за все товары:{" "}
              <span className={style.span}>{allPrice}</span> руб
            </div>
          ) : (
            <div className={style.allprice}>
              {" "}
              Корзина пустая, добавьте товары
            </div>
          )}
        </div>
        {allItems && allItems?.length > 0 && (
          <div className={style.form}>
            <FormOrderBy />
          </div>
        )}
      </div>
    </>
  );
};

export default OrderBy;
