import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const createDeviceOption = createAsyncThunk(
  "@@options-select/create-device-option",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await $host.post(`api/options-select/`, payload);
      console.log(
        response.data,
        "ЧТО приходит нам по сохранению опций для товара"
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// export const updateDeviceOption = createAsyncThunk(
//   "@@options-select/update-device-option",
//   async ({ id, formData }, { rejectWithValue }) => {
//     try {
//       const response = await $host.patch(`api/options-select/${id}`, formData);
//       return response.data;
//     } catch (error) {
//       console.error(error);
//       return rejectWithValue(error.response.data.message);
//     }
//   }
// );

export const loadDeviceOptionId = createAsyncThunk(
  "@@options-select/load-device-option-id",
  async (id, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/options-select/${id}`);
      // console.log(
      //   response.data.dataOptions,
      //   "test RESSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS"
      // );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadOptionsSelect = createAsyncThunk("@@options-select/load", () =>
  $host.get("api/options-select")
);
export const loadOptionsSelectDevice = createAsyncThunk(
  "@@options-select-device/load",
  () => $host.get("api/options-select/device")
);
