import React, { useEffect, useState } from "react";
import { ReactComponent as SvgEdit } from "../svg/edit.svg";
import { ReactComponent as SvgDelete } from "../svg/delete.svg";
import stl from "./device.module.scss";
import CardGallery from "../../../../widgets/cardGallery/CardGallery";
import Modal from "../../../../shared/ui/modal/Modal";
import WorkToXLS from "../../../../features/workToXls/WorkToXLS";
import Prices from "../../../../features/prices/Prices";
import { useDispatch, useSelector } from "react-redux";
import { loadPrice } from "../../../../app/http/priceApi";
import { selectLoadedPricesById } from "../../../../app/redux/store/priceStore/priceSlice";
import OptionsSelect from "../../../../features/optionsSelect/OptionsSelect";
import { FaImage } from "react-icons/fa";

const Device = (props) => {
  const { predelClick, editClick, imageClick, ...device } = props;
  const dispatch = useDispatch();

  // console.log(device.id, "айди!!!!!!!!!!!!!!!!!!!!!!");
  const {
    id,
    price,
    tarea,
    about,
    qnt,
    height,
    name,
    salePrice,
    isInSale,
    imageProducts,
    pricechecked,
  } = device;

  const [modalActive, setModalActive] = useState(false);
  const [modalType, setModalType] = useState(null);
  // const loadedPrices = useSelector((state) => state.price.loadedPrices);
  const loadedPrices = useSelector((state) =>
    selectLoadedPricesById(state, id)
  );
  const optionsDevice = useSelector((state) => state.optionsSelect.options);
  console.log(optionsDevice, "!@@@JHJH@@@@@@@@@@@@@@@");

  // console.log(loadedPrices, "консоль!!!!!!!!!!!");

  // console.log(loadedPrices, "JDSGJJDI!!!!!!!!!!!!!");

  const workToXLS = () => {
    setModalType("xls");
    setModalActive(true);
  };

  const showPriceModal = () => {
    setModalType("price");
    setModalActive(true);
  };

  const showOptional = () => {
    setModalType("options");
    setModalActive(true);
  };
  // const imageload = useSelector(selectAllGallery);
  // console.log(imageload);

  useEffect(() => {
    if (id) {
      dispatch(loadPrice(id)); // Вызываем загрузку прайса
    }
  }, [id, optionsDevice]);
  // useEffect(() => {
  //   if (id) {
  //     dispatch(loadPrice(id)); // Вызываем загрузку прайса
  //   }
  // }, [id]);
  // console.log(device);
  return (
    <div className={stl.block}>
      <Modal visible={modalActive} setVisible={setModalActive}>
        {
          modalType === "xls" ? (
            // это для одного товара прайс
            <WorkToXLS id={id} setVisible={setModalActive} />
          ) : modalType === "options" ? ( // Используйте тернарный оператор здесь
            // Проверяем, открыто ли модальное окно для прайс-листа
            <OptionsSelect device={id} setVisible={setModalActive} /> // Если да, отображаем компонент Prices
          ) : modalType === "price" ? ( // Используйте тернарный оператор здесь
            // // это для массового добавления/обновления
            <Prices prices={loadedPrices} setVisible={setModalActive} /> // Если да, отображаем компонент Prices
          ) : null // Вы можете указать, что следует отрисовывать, если modalType не соответствует "price"
        }
      </Modal>
      {/* {images.map((image, index) => (
        <img
          key={index}
          className={stl.block_img}
          src={process.env.REACT_APP_API_URL + image.img}
          alt={name}
        />
      ))} */}
      <div className={stl.gallery}>
        <CardGallery images={imageProducts} />
      </div>

      <div className={stl.content}>
        <span>{name}</span>
        <br />
        {height !== 0 && (
          <span>
            Высота {height} см.
            <br />
          </span>
        )}
        <br />
        {/* <br /> */}
        {/* Описание {tarea}см. */}
        <br />
        {qnt !== 0 && (
          <span>
            Количество {qnt} шт.
            <br />
          </span>
        )}
        <br />
        {pricechecked && <p>Цена от: {price}руб.</p>}
        {!pricechecked && <p>Цена: {price}руб.</p>}
      </div>
      <div className={stl.icons}>
        <div className={stl.prices}>
          {" "}
          {
            loadedPrices.length > 0 && (
              <div className={stl.xls} onClick={workToXLS}>
                Обновить прайс
              </div>
            )
            //  : (
            //   <div className={stl.xls} onClick={workToXLS}>
            //     Загрузить прайс
            //   </div>
            // )
          }
          {pricechecked && loadedPrices.length === 0 && (
            <div className={stl.xls} onClick={workToXLS}>
              Загрузить прайс
            </div>
          )}
          {loadedPrices.length > 0 && (
            <button className={stl.priceButton} onClick={showPriceModal}>
              показать прайс
            </button>
          )}
          {/* {loadedPrices.length > 0 && ( */}
          <button className={stl.optionButton} onClick={showOptional}>
            {optionsDevice.hasOwnProperty(id)
              ? "изменить опции"
              : "добавить опции"}
          </button>
          {/* )} */}
        </div>
        <div>
          <FaImage
            onClick={() => imageClick(device.id)}
            // onClick={() => editClick(id)}
            // size="30px"
            // color="green"
            // style={{ fontSize: "30px", color: "green" }}
            className={stl.svg_image}
          />
        </div>
        <div>
          <SvgEdit
            onClick={() => editClick(device, id)}
            // onClick={() => editClick(id)}
            className={stl.svg_edit}
          />
        </div>
        <div>
          <SvgDelete onClick={() => predelClick(id)} className={stl.svg_del} />
        </div>
      </div>
    </div>
  );
};

export default Device;
