import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const totalPriceSlice = createSlice({
  name: "totalPrice",
  initialState: {
    basedPrice: 0,
    optionPrices: {}, // Изменение на объект для хранения цен для различных опций
    sumPrice: 0,
    optionsFromBasket: {},
    limitSquare: 0,
    idProduct: null,
    dimensions: { height: 0, qnt: 0 },
  },
  reducers: {
    setBasedPrice: (state, action) => {
      const { price, idProduct, dimension } = action.payload;
      state.basedPrice = price;
      if (dimension) {
        console.log(dimension, "TESTTTTTTTTTTTTTTTTTTTTTT");
        state.dimensions.height = dimension.height;
        state.dimensions.qnt = dimension.qnt;
      }
      state.sumPrice =
        state.basedPrice +
        Object.values(state.optionPrices).reduce(
          (acc, optionPrice) => acc + optionPrice,
          0
        );
      state.idProduct = idProduct; // Добавляем idProduct к состоянию
    },
    setLimitSquare: (state, action) => {
      const { width, height } = action.payload;
      state.limitSquare = (width * height) / 1000000;
    },

    updateTotalPrice: (state, action) => {
      const { price, name, nameOptionValue, checked = true } = action.payload;
      console.log(checked, "ПРОВЕРКА!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      // Обновляем или добавляем цену для выбранной опции

      if (checked === false) {
        delete state.optionPrices[name];
        delete state.optionsFromBasket[name];
      } else {
        state.optionPrices[name] = price;
        state.optionsFromBasket[name] = nameOptionValue;
      }
      // state.optionPrices[name] = nameModel;

      // Вычисляем сумму всех цен опций
      state.sumPrice =
        state.basedPrice +
        Object.values(state.optionPrices).reduce(
          (acc, optionPrice) => acc + optionPrice,
          0
        );
    },
    resetOptionPrices: (state) => {
      // Сбрасываем значения в объекте optionPrices
      state.optionPrices = {};
      // state.optionsFromBasket = {};
      // state.sumPrice = state.basedPrice; // Обновляем sumPrice
    },
  },
});

export const {
  updateTotalPrice,
  setBasedPrice,
  setLimitSquare,
  resetOptionPrices,
} = totalPriceSlice.actions;

export const selectBasedPrice = (state) => state.totalPrice.basedPrice;
export const selectOptionPrices = (state) => state.totalPrice.optionPrices;

export const selectSumPrice = createSelector(
  [selectBasedPrice, selectOptionPrices],
  (basedPrice, optionPrices) =>
    basedPrice +
    Object.values(optionPrices).reduce(
      (acc, optionPrice) => acc + optionPrice,
      0
    )
);

export default totalPriceSlice.reducer;
