import React from "react";
import style from "./prices.module.scss"; // Подключаем стили

const Prices = ({ prices }) => {
  // Группируем цены по ширине и высоте
  const pricesByWidthAndHeight = prices.reduce((acc, price) => {
    acc[price.height] = {
      ...acc[price.height],
      [price.width]: price.price,
    };
    return acc;
  }, {});

  // Извлекаем уникальные значения ширины и высоты
  const uniqueWidths = Array.from(
    new Set(prices.map((price) => price.width))
  ).sort((a, b) => a - b);
  const uniqueHeights = Array.from(
    new Set(prices.map((price) => price.height))
  ).sort((a, b) => a - b);

  return (
    <div className={style["price-container"]}>
      <h2>Прайс-лист</h2>
      <table className={style["price-table"]}>
        <thead>
          <tr>
            <th>{"высота цветка->"}</th>
            {uniqueWidths.map((width) => (
              <th key={width}>{width}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {uniqueHeights.map((height) => (
            <tr key={height}>
              <td>{height}</td>
              {uniqueWidths.map((width) => (
                <td key={width}>
                  {pricesByWidthAndHeight[height][width] || ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Prices;
