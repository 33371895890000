import React, { useEffect, useRef, useState } from "react";
import stl from "./slider.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SvgPlus } from "./svg/plus.svg";
import { ReactComponent as SvgEmpty } from "./svg/image.svg";
import { ReactComponent as SvgDelete } from "./svg/delete.svg";
import {
  addSlide,
  deleteSlide,
  loadSlides,
  updateSlide,
} from "../../../app/http/slideApi";
import {
  clearImage,
  selectAllSlider,
  setImage,
} from "../../../app/redux/store/sliderStore/sliderSlice";
import Modal from "../../../shared/ui/modal/Modal";
import ImageCropFromSlider from "../../../features/imageCrop/ImageCropFromSlider";

const Slider = () => {
  const imageload = useSelector(selectAllSlider);
  const uploadedImage = useSelector((state) => state.slider.uploadedImage); // Получите изображение из store
  const dispatch = useDispatch();
  const [modalActive, setModalActive] = useState(false);
  const [file, setFile] = useState(null);

  const [currentImg, setCurrentImg] = useState(0);
  const [hoveredImg, setHoveredImg] = useState(-1);
  // const [selectedFile, setSelectedFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [slideInfo, setSlideInfo] = useState({
    id: null,
    title: "",
    text: "",
    link: "",
    alt: "",
  }); // Состояние для хранения данных о слайде

  const fileInputRef = useRef(null);

  const handlerAdd = (e) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    dispatch(setImage(imageUrl));
    console.log(imageUrl);
  };

  const handlerAddCrop = (e) => {
    if (!e.file2) {
      setFile({ file1: e.file1 });
      // const imageUrl = URL.createObjectURL(e.file1);
      // dispatch(setImage(imageUrl));
    } else {
      setFile((prevState) => ({
        ...prevState,
        file2: e.file2,
      }));
    }
  };

  const handleSaveWithNullSlide = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (file.file1) {
      formData.append("img1", file.file1);
    }
    if (file.file2) {
      formData.append("img2", file.file2);
    }
    if (!file.file1 && !file.file2) {
      alert("Нет изображения");
      return;
    }
    dispatch(addSlide(formData)).then(clearImage());
  };

  // Обработчик сохранения изменений
  const handleSave = async (e) => {
    e.preventDefault();
    // console.log(isEditing);
    // Создаем объект FormData и добавляем информацию о слайде
    const formData = new FormData(e.target);
    if (isEditing) {
      dispatch(updateSlide({ id: slideInfo.id, formData }));
    } else {
      if (file.file1) {
        formData.append("img1", file.file1);
      }
      if (file.file2) {
        formData.append("img2", file.file2);
      }
      if (!file.file1 && !file.file2) {
        alert("Нет изображения");
        return;
      }
      dispatch(addSlide(formData)).then(clearImage());
    }
    console.log(currentImg, "текущий индекс картинки");

    // setSlideInfo({
    //   id: null,
    //   title: "",
    //   text: "",
    //   link: "",
    //   alt: "",
    // });
  };

  const handleClick = (index) => {
    setCurrentImg(index);
    console.log(imageload[currentImg]);
    // setSlideInfo(
    //   imageload[index] || { title: "", text: "", link: "", alt: "" }
    // );
    setSlideInfo((prev) => ({
      id: imageload[index]?.id || null, // Устанавливаем id выбранного слайда или null, если слайд не найден
      title: imageload[index]?.title || "",
      text: imageload[index]?.text || "",
      link: imageload[index]?.link || "",
      alt: imageload[index]?.alt || "",
    }));
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSlideInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlerRemove = (e) => {
    dispatch(deleteSlide(e));
  };

  const handleSvgPlusClick = () => {
    setModalActive(true);
    // fileInputRef.current.click();
    setSlideInfo({
      title: "",
      text: "",
      link: "",
      alt: "",
    });
    // dispatch(clearImage());
    setIsEditing(false);
  };
  useEffect(() => {
    // При монтировании компонента запрашиваем слайды
    dispatch(loadSlides());
  }, [dispatch]);
  useEffect(() => {
    // После загрузки слайдов, установите начальное состояние инпутов
    if (imageload.length > 0) {
      setSlideInfo(imageload[0]);
    }
    console.log(uploadedImage, "состояние текущей картинки");
  }, []);

  return (
    <>
      <Modal visible={modalActive} setVisible={setModalActive}>
        <ImageCropFromSlider
          setVisible={setModalActive}
          id={slideInfo.id}
          handlerAddCrop={handlerAddCrop}
          // aspect={2.569}
        />
      </Modal>
      {imageload.length > 0 || uploadedImage ? (
        <div className={stl.wrapper}>
          {/* <div className={stl.bigimg}> */}
          {/* Отображаем загруженное изображение, если оно есть */}
          {uploadedImage ? (
            <img
              className={stl.bigimg}
              src={uploadedImage}
              alt="Uploaded Slide"
            />
          ) : (
            <img
              className={stl.bigimg}
              src={
                process.env.REACT_APP_API_URL +
                "slider/" +
                imageload[currentImg]?.imageUrl
              }
              alt=""
            />
          )}
          {/* </div> */}
          <form onSubmit={handleSave} className={stl.inputs}>
            <input
              type="text"
              name="title"
              placeholder="Заголовок"
              value={slideInfo.title}
              onChange={handleInputChange}
            />
            <textarea
              // type="text"
              name="text"
              placeholder="Текст"
              value={slideInfo.text}
              onChange={handleInputChange}
            />
            {/* <input
              type="text"
              name="title"
              placeholder="Размеры"
              value={slideInfo.title}
              onChange={handleInputChange}
            /> */}
            {/* <input
              type="text"
              name="title"
              placeholder="Стоимость"
              value={slideInfo.title}
              onChange={handleInputChange}
            /> */}
            <input
              type="text"
              name="link"
              placeholder="Ссылка"
              value={slideInfo.link}
              onChange={handleInputChange}
            />
            {/* <input
              type="text"
              name="link"
              placeholder="Описание ссылки"
              value={slideInfo.link}
              onChange={handleInputChange}
            /> */}
            <input
              type="text"
              name="alt"
              placeholder="Описание для alt"
              value={slideInfo.alt}
              onChange={handleInputChange}
            />
            {/* <input
              type="file"
              style={{
                display: "none",
                // visibility: "hidden",
              }}
              ref={fileInputRef}
              onChange={handlerAdd}
              multiple
            /> */}
            {isEditing ? <button>Обновить</button> : <button>Сохранить</button>}
            {/* <button>Сохранить</button> */}
          </form>
          <div className={stl.nav}>
            {imageload.length > 0 &&
              imageload.map((item, index) => (
                <div
                  key={index}
                  className={stl.imgWrapper}
                  onMouseEnter={() => setHoveredImg(index)}
                  onMouseLeave={() => setHoveredImg(-1)}>
                  {index === hoveredImg && (
                    <SvgDelete
                      className={stl.deleteSvg}
                      onClick={() => handlerRemove(item.imageUrl)}
                    />
                  )}
                  <img
                    className={stl.img}
                    onClick={() => handleClick(index)}
                    src={
                      process.env.REACT_APP_API_URL + "slider/" + item.imageUrl
                    }
                    alt=""
                  />
                </div>
              ))}
            {uploadedImage && (
              <img
                className={stl.img}
                src={uploadedImage}
                alt="Uploaded Slide"
              />
            )}
            {!uploadedImage && imageload.length < 6 && (
              <div className={stl.img}>
                <SvgPlus className={stl.svg} onClick={handleSvgPlusClick} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={stl.wrapper}>
          <div className={stl.bigimg}>
            <SvgEmpty className={stl.svgimage} />
          </div>
          {/* <div className={stl.inputs}></div> */}
          <form onSubmit={handleSaveWithNullSlide} className={stl.inputs}>
            <input
              type="text"
              name="title"
              placeholder="Заголовок"
              value={slideInfo.title}
              // value={imageload[currentImg]?.title || slideInfo.title}
              onChange={handleInputChange}
            />
            <textarea
              // type="text"
              name="text"
              placeholder="Текст"
              value={slideInfo.text}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="link"
              placeholder="Ссылка"
              value={slideInfo.link}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="alt"
              placeholder="Описание для alt"
              value={slideInfo.alt}
              onChange={handleInputChange}
            />
            {/* <input
              type="file"
              style={{
                display: "none",
                // visibility: "hidden",
              }}
              ref={fileInputRef}
              onChange={handlerAdd}
              multiple
            /> */}
            <button>Добавить</button>
          </form>
          <div className={stl.nav}>
            {imageload.length > 0 &&
              imageload.map((item, index) => (
                <div
                  key={index}
                  className={stl.imgWrapper}
                  onMouseEnter={() => setHoveredImg(index)}
                  onMouseLeave={() => setHoveredImg(-1)}>
                  {index === hoveredImg && (
                    <SvgDelete
                      className={stl.deleteSvg}
                      onClick={() => handlerRemove(item.imageUrl)}
                    />
                  )}
                  <img
                    className={stl.img}
                    onClick={() => handleClick(index)}
                    src={
                      process.env.REACT_APP_API_URL + "slider/" + item.imageUrl
                    }
                    alt=""
                  />
                </div>
              ))}
            {uploadedImage && (
              <img
                className={stl.img}
                src={uploadedImage}
                alt="Uploaded Slide"
              />
            )}
            {!uploadedImage && imageload.length < 6 && (
              <div className={stl.img}>
                <SvgPlus className={stl.svg} onClick={handleSvgPlusClick} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Slider;
