import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPrice, loadPrice } from "../../app/http/priceApi";
import style from "./worktoxls.module.scss";
import { resetStatus } from "../../app/redux/store/priceStore/priceSlice";

const WorkToXLS = (props) => {
  const [excelFile, setExcelFile] = useState(null);
  const dispatch = useDispatch();
  const price = useSelector((state) => state.price); // Замените на вашу точную структуру состояния
  const timerIdRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setExcelFile(selectedFile);
  };

  const handleSaveExcel = async () => {
    if (excelFile) {
      const formData = new FormData();
      formData.append("excelFile", excelFile);
      dispatch(addPrice({ id: props.id, formData }));
    }
  };

  useEffect(() => {
    if (price.status === "succeeded") {
      timerIdRef.current = setTimeout(() => {
        dispatch(loadPrice(props.id));
        dispatch(resetStatus());
        props.setVisible(false); // Close the modal window after 2 seconds
      }, 1000); // 2 seconds in milliseconds
    }

    // Clean up the timer when the component is unmounted
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, [price.status, props]);

  return (
    <div className={style.WorkToXLS}>
      <input
        type="file"
        name="uploadedFile"
        accept=".xls, .xlsx"
        onChange={handleFileChange}
        className={style.WorkToXLSInput}
      />
      <button onClick={handleSaveExcel} disabled={!excelFile}>
        {excelFile ? "Сохранить" : "Сохранить"}
      </button>
      {price.status === "loading" && <p>Загрузка...</p>}
      {price.status === "failed" && <p>Error: {price.error}</p>}
      {price.status === "succeeded" && <p>Успешно</p>}
    </div>
  );
};

export default WorkToXLS;
