import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDevice, editDevice } from "../../../../app/http/deviceApi";
import {
  resetImages,
  selectAllGallery,
} from "../../../../app/redux/store/imageSlice/imageSlice";
import Galery from "../../../../widgets/Galery/Galery";
import Input from "../../../../widgets/Inputs/Input";
import stl from "./contentAdvanced.module.scss";
import { tempImagesClear } from "../../../../app/http/imageApi";

import { loadPrice } from "../../../../app/http/priceApi";

const ContentAdvanced = (props) => {
  const dispatch = useDispatch();

  // console.log(props.editId);

  const [formData, setFormData] = useState({
    // img: [],
    name: "",
    tarea: "",
    about: "",
    qnt: 0,
    height: 0,
    price: 0,
    pricechecked: false,
    isVisible: props?.goods?.isVisible || false,
    priceboxes: null,
    salePrice: 0,
    isInSale: props?.goods?.isInSale || false,
    boxes: false,
  });
  const updateFormData = (inputName, inputValue) => {
    setFormData({
      ...formData,
      [inputName]: inputValue,
    });
    console.log(formData, "sdgsdgsdgsdgsdgsdgsdgsd");
  };

  // const validateFormData = (formData) => {
  //   const { name, tarea, qnt, lngt, price } = formData;

  //   // Проверяем, что все обязательные поля заполнены
  //   if (!name || !tarea || !qnt || !lngt || !price) {
  //     return false;
  //   }

  //   // Проверяем, что значения полей корректны
  //   if (isNaN(Number(qnt)) || isNaN(Number(lngt)) || isNaN(Number(price))) {
  //     return false;
  //   }

  //   return true;
  // };

  const handlerSubmit = (e) => {
    e.preventDefault();
    const preFormData = new FormData();
    preFormData.append("name", formData.name);
    preFormData.append("tarea", formData.tarea);
    preFormData.append("about", formData.about);
    preFormData.append("price", formData.price);
    preFormData.append("qnt", formData.qnt);
    preFormData.append("height", formData.height);
    preFormData.append("pricechecked", formData.pricechecked);
    preFormData.append("priceboxes", JSON.stringify(formData.priceboxes));
    preFormData.append("boxes", formData.boxes);
    preFormData.append("optionchecked", formData.optionchecked);
    preFormData.append("id", props.id);

    dispatch(createDevice(preFormData))
      .then(dispatch(resetImages()))
      .then(() => {
        props.setVisible(false);
      });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const preFormData = new FormData();
    if (formData.name != "") preFormData.append("name", formData.name);
    if (formData.tarea != "") preFormData.append("tarea", formData.tarea);
    if (formData.about != "") preFormData.append("about", formData.about);
    if (formData.price != "") preFormData.append("price", formData.price);
    if (formData.qnt != "") preFormData.append("qnt", formData.qnt);
    if (formData.height != "") preFormData.append("height", formData.height);
    // if (formData.height != "") preFormData.append("height", formData.height);
    preFormData.append("priceboxes", JSON.stringify(formData.priceboxes));

    if (formData.salePrice != "")
      preFormData.append("salePrice", formData.salePrice);
    // if (formData.isInSale) preFormData.append("isInSale", formData.isInSale);
    // preFormData.append("id", props.id);
    preFormData.append("isInSale", formData.isInSale ? "true" : "false");
    preFormData.append("isVisible", formData.isVisible ? "true" : "false");
    dispatch(
      editDevice({
        id: props.editId,
        device: preFormData,
      })
    )
      .then(dispatch(resetImages()))
      .then(() => {
        props.setVisible(false);
      });
  };

  // useEffect(() => {
  //   if (props.id) {
  //     dispatch(loadPrice(props.id)); // Вызываем загрузку прайса
  //   }
  // }, [props.id]);

  if (props.goods) {
    return (
      <div className={stl.wrapper}>
        <div>{/* <Galery images={allImg} /> */}</div>

        {/* <div><Input id={props.goods.id} goods={props.goods[props.id - 1]} /></div> */}
        <div>
          <Input goods={props.goods} updateFormData={updateFormData} />
        </div>
        <div className={stl.actions}>
          <button className={stl.btn} onClick={handleEdit}>
            {" "}
            сохранить
          </button>
          <button
            className={stl.btn}
            onClick={() => {
              props.setVisible(false);
              // dispatch(tempImagesClear());
            }}>
            {" "}
            закрыть
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={stl.wrapper}>
        <div>
          <Input updateFormData={updateFormData} />
        </div>
        <div className={stl.actions}>
          <button className={stl.btn} onClick={handlerSubmit}>
            сохранить
          </button>
          <button
            className={stl.btn}
            onClick={() => {
              props.setVisible(false);
              // dispatch(tempImagesClear());
            }}>
            {" "}
            закрыть
          </button>
        </div>
      </div>
    );
  }
};

export default ContentAdvanced;
