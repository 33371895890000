import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { updateOption } from "../../../app/http/optionApi";
import { ReactComponent as SvgImage } from "./svg/image.svg";

import style from "./optionsModal.module.scss";

const OptionModalEdit = ({ setVisible, id, options }) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showColorOption, setShowColorOption] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    tarea: "",
    about: "",
    priceChange: 0,
    type: "",
    color: "",
    OptionalType: "",
    exist: "",
    imageUrl: "",
    url: "",
  });

  const fileInputRef = useRef(null);
  const handleSvgPlusClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(file);
  };

  // Создаем эффект, который заполняет formData на основе id и options

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChangeColor = (event) => {
    setShowColorOption(event.target.checked);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();

    // Добавляем файл картинки в formData
    if (fileInputRef.current.files[0]) {
      formDataToSubmit.append("img", fileInputRef.current.files[0]);
    }

    // Добавляем введенные параметры в formData
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
    // console.log(formDataToSubmit, "проверка сохранения опции");
    dispatch(
      updateOption({
        id,
        formDataToSubmit,
      })
    );
    setVisible(false);
  };
  useEffect(() => {
    if (id && options.length > 0) {
      const optionToEdit = options.find((option) => option.id === id);

      if (optionToEdit) {
        setFormData({
          name: optionToEdit.name,
          tarea: optionToEdit.tarea,
          priceChange: optionToEdit.priceChange,
          type: optionToEdit.type,
          OptionalType: optionToEdit.OptionalType,
          about: optionToEdit.about,
          imageUrl: optionToEdit.imageUrl,
          url: optionToEdit.url,
          color: optionToEdit.color,
          exist: optionToEdit.exist,
        });
      }
    }
  }, [id, options]);

  return (
    <div className={style.wrapper}>
      <p>Опция</p>
      <h5>
        Подсказка: если создаёте цвета, то выбирайте тип кнопки: Радиокнопка
      </h5>
      {!formData.imageUrl ? <h5>Добавить изображение</h5> : ""}
      {formData.imageUrl && !selectedImage ? (
        <img
          src={process.env.REACT_APP_API_URL + "options/" + formData.imageUrl}
          className={style.option}
          alt="Опция"
          onClick={handleSvgPlusClick}
        />
      ) : selectedImage ? (
        <img
          src={selectedImage}
          className={style.option}
          alt="новое изображение"
        />
      ) : (
        <SvgImage className={style.svg_plus} onClick={handleSvgPlusClick} />
      )}
      <div className={style.inputs}>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <label htmlFor="">
          Название опции{" "}
          <input
            type="text"
            name="name"
            placeholder="Название опции"
            onChange={handleChange}
            value={formData.name} // Устанавливаем значение инпута равным значению в formData
          />
        </label>
        <label>
          <input
            type="checkbox"
            name="limitSquareCheck"
            // checked={formData.limitSquare}
            onChange={handleCheckboxChangeColor}
          />
          Цвет упаковки
        </label>
        {showColorOption && (
          <div>
            <label htmlFor="colorPicker">Выберите цвет упаковки:</label>
            <input
              type="color"
              name="color"
              value={formData.color}
              onChange={handleChange}
            />
          </div>
        )}
        <label htmlFor="">
          Описание для админки{" "}
          <input
            type="text"
            name="about"
            placeholder="Описание для админки"
            onChange={handleChange}
            value={formData.about}
          />
        </label>
        <label htmlFor="">
          Описание для пользователей{" "}
          <input
            type="text"
            name="tarea"
            placeholder="Описание опции"
            onChange={handleChange}
            value={formData.tarea} // Устанавливаем значение инпута равным значению в formData
          />{" "}
        </label>
        {/* <input
          type="text"
          name="url"
          placeholder="ссылка на товар, если есть"
          onChange={handleChange}
          value={formData.url} // Устанавливаем значение инпута равным значению в formData
        /> */}
        <label htmlFor="">
          Наценка{" "}
          <input
            type="number"
            name="priceChange"
            placeholder="Прайсовая наценка"
            onChange={handleChange}
            value={formData.priceChange} // Устанавливаем значение инпута равным значению в formData
          />{" "}
        </label>
        {/* <label htmlFor="typeSelect">Тип наценки:</label>
        <select
          name="type"
          onChange={handleChange}
          value={formData.type} // Устанавливаем значение select равным значению в formData
        >
          <option value="Нет">Нет</option>
          <option value="Процент">Процент</option>
          <option value="Сумма">Сумма</option>
        </select> */}
        {/* <p></p> */}
        <label htmlFor="typeSelect">Тип кнопки (если нужна):</label>
        <select
          name="OptionalType"
          onChange={handleChange}
          value={formData.OptionalType} // Устанавливаем значение select равным значению в formData
        >
          <option value="Нет">Нет</option>
          <option value="checkbox">Флажок</option>
          <option value="radio">Радиокнопка</option>
        </select>
        <label htmlFor="typeSelect">Доступность на сайте:</label>
        <select
          name="exist"
          onChange={handleChange}
          value={formData.exist} // Устанавливаем значение select равным значению в formData
        >
          <option value="true">Да</option>
          <option value="false">Нет</option>
        </select>
        <p></p>
      </div>
      <button onClick={handleSave}>Сохранить</button>
    </div>
  );
};

export default OptionModalEdit;
