import React, { useEffect, useState } from "react";
import stl from "./input.module.scss";

const Input = (props) => {
  const [formData, setFormData] = useState({
    s: { quantity: "", price: "" },
    m: { quantity: "", price: "" },
    l: { quantity: "", price: "" },
  });

  const handleInputForm = (size, field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [size]: {
        ...prevFormData[size],
        [field]: value,
      },
    }));
    // updateFormData(size + "-" + field, value);
    // props.updateFormData("priceboxes", formData);
  };
  useEffect(() => {
    if (props.goods && props.goods.PriceBox) {
      const { l, m, s } = props.goods.PriceBox;

      // Проверка наличия свойств l, m и s
      if (l && m && s) {
        // Создание нового объекта состояния formData, копируя текущее состояние и обновляя только необходимые поля
        const newFormData = {
          ...formData,
          s: {
            ...formData.s,
            quantity: s.quantity,
            price: s.price,
          },
          m: {
            ...formData.m,
            quantity: m.quantity,
            price: m.price,
          },
          l: {
            ...formData.l,
            quantity: l.quantity,
            price: l.price,
          },
        };

        // Обновление состояния formData
        setFormData(newFormData);
        // Отправка объекта в форму
        props.updateFormData("priceboxes", newFormData); // передаем newFormData вместо formData
      } else {
        console.warn("PriceBox properties are missing:", { l, m, s });
      }
    }
  }, [props.goods?.PriceBox]); // Зависимость только от props.goods

  console.log(props);
  const handleInputChange = (e) => {
    props.updateFormData(e.target.name, e.target.value);
  };

  const [isChecked, setChecked] = useState({
    boxes: false, // Флажок для бокса или корзины
    pricechecked: false, // Флажок для прайсового товара
  });
  const [isSale, setSale] = useState(props.goods && props.goods.isInSale);
  const [isVisible, setisVisible] = useState(
    props.goods && props.goods.isVisible
  );
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setChecked((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    props.updateFormData(name, checked);
  };
  const handleSaleChange = () => {
    setSale(!isSale);
    props.updateFormData("isInSale", !isSale);
  };
  const handleVisibleChange = () => {
    setisVisible(!isVisible);
    props.updateFormData("isVisible", !isVisible);
  };

  //     useEffect(()=> {
  // if(props.goods) {

  // }
  //     },[])

  if (props.goods) {
    return (
      <div className={stl.wrap}>
        <div className={stl.boxnum}>
          <label className={stl.text}>Наименование</label>
          <input
            className={stl.inp}
            name="name"
            type="text"
            defaultValue={props.goods.name}
            onChange={handleInputChange}
          />

          <label className={stl.text}>Описание для флористов</label>
          {/* <div style={{display:'flex'}}> */}
          <textarea
            className={stl.inparea}
            name="tarea"
            defaultValue={props.goods.tarea}
            onChange={handleInputChange}
          />
          <label className={stl.text}>Описание для пользователей</label>
          <textarea
            className={stl.inparea}
            name="about"
            defaultValue={props.goods.about}
            onChange={handleInputChange}
          />
          <div className={stl.check}>
            <label>
              <input
                type="checkbox"
                name="isVisible"
                checked={isVisible}
                onChange={handleVisibleChange}
              />
              Показывать в товарах
            </label>
          </div>
          {/* </div> */}

          {!props.goods.pricechecked && !props.goods.priceboxes && (
            <div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="price"
                  type="number"
                  defaultValue={props.goods.price}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className={stl.text}>Цена по акции</label>
                <input
                  className={stl.inp}
                  name="salePrice"
                  type="number"
                  defaultValue={props.goods.salePrice}
                  onChange={handleInputChange}
                />
                <div className={stl.check}>
                  <label>
                    <input
                      type="checkbox"
                      name="isInSale"
                      checked={isSale}
                      onChange={handleSaleChange}
                    />
                    Включить акцию
                  </label>
                </div>
              </div>
              <div>
                <label className={stl.text}>Кол-во цветов</label>
                <input
                  className={stl.inp}
                  name="qnt"
                  type="number"
                  defaultValue={props.goods.qnt}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className={stl.text}>Высота стебля</label>
                <input
                  className={stl.inp}
                  name="height"
                  type="number"
                  defaultValue={props.goods.height}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}
          {props.goods.pricechecked && <p>Это Прайсовый Товар!!!</p>}
        </div>
        {props.goods.priceboxes && (
          <>
            <div style={{ border: "1px solid grey" }}>
              <div>
                <h5 style={{ textAlign: "center" }}>S</h5>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="s-quantity"
                  type="number"
                  defaultValue={props.goods?.PriceBox?.s?.quantity}
                  onChange={(e) =>
                    handleInputForm("s", "quantity", e.target.value)
                  }
                />
              </div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="s-price"
                  type="number"
                  defaultValue={props.goods?.PriceBox?.s?.price}
                  onChange={(e) =>
                    handleInputForm("s", "price", e.target.value)
                  }
                />
              </div>
            </div>
            <div style={{ border: "1px solid grey" }}>
              <div>
                <h5 style={{ textAlign: "center" }}>M</h5>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="m-quantity"
                  type="number"
                  defaultValue={props.goods?.PriceBox?.m?.quantity}
                  onChange={(e) =>
                    handleInputForm("m", "quantity", e.target.value)
                  }
                />
              </div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="m-price"
                  type="number"
                  defaultValue={props.goods?.PriceBox?.m?.price}
                  onChange={(e) =>
                    handleInputForm("m", "price", e.target.value)
                  }
                />
              </div>
            </div>
            <div style={{ border: "1px solid grey" }}>
              <div>
                <h5 style={{ textAlign: "center" }}>L</h5>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="l-quantity"
                  type="number"
                  defaultValue={props.goods?.PriceBox?.l?.quantity}
                  onChange={(e) =>
                    handleInputForm("l", "quantity", e.target.value)
                  }
                />
              </div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="l-price"
                  type="number"
                  defaultValue={props.goods?.PriceBox?.l?.price}
                  onChange={(e) =>
                    handleInputForm("l", "price", e.target.value)
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className={stl.wrap}>
        <div className={stl.boxnum}>
          <label className={stl.text}>Наименование</label>
          <input
            className={stl.inp}
            type="text"
            name="name"
            onChange={handleInputChange}
          />

          <label className={stl.text}>Описание для флористов</label>
          <textarea
            className={stl.inparea}
            name="tarea"
            onChange={handleInputChange}
          />
          <label className={stl.text}>Описание для пользователей</label>
          <textarea
            className={stl.inparea}
            name="about"
            onChange={handleInputChange}
          />

          {!isChecked.boxes && !isChecked.pricechecked && (
            <div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="price"
                  type="number"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="qnt"
                  type="number"
                  // defaultValue={props.goods.qnt}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className={stl.text}>Высота стебля</label>
                <input
                  className={stl.inp}
                  name="height"
                  type="number"
                  // defaultValue={props.goods.lngt}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          )}

          {!isChecked.pricechecked && (
            <div className={stl.check}>
              <label>
                <input
                  type="checkbox"
                  name="boxes"
                  checked={isChecked.boxes}
                  onChange={handleCheckboxChange}
                />
                Это бокс или корзина
              </label>
            </div>
          )}
          {!isChecked.boxes && (
            <div className={stl.check}>
              <label>
                <input
                  type="checkbox"
                  name="pricechecked"
                  checked={isChecked.pricechecked}
                  onChange={handleCheckboxChange}
                />
                Прайсовый товар
              </label>
            </div>
          )}
        </div>
        {isChecked.boxes && (
          <>
            <div style={{ border: "1px solid grey" }}>
              <div>
                <h5 style={{ textAlign: "center" }}>S</h5>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="s-quantity"
                  type="number"
                  value={formData.s.quantity}
                  onChange={(e) =>
                    handleInputForm("s", "quantity", e.target.value)
                  }
                />
              </div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="s-price"
                  type="number"
                  value={formData.s.price}
                  onChange={(e) =>
                    handleInputForm("s", "price", e.target.value)
                  }
                />
              </div>
            </div>
            <div style={{ border: "1px solid grey" }}>
              <div>
                <h5 style={{ textAlign: "center" }}>M</h5>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="m-quantity"
                  type="number"
                  value={formData.m.quantity}
                  onChange={(e) =>
                    handleInputForm("m", "quantity", e.target.value)
                  }
                />
              </div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="m-price"
                  type="number"
                  value={formData.m.price}
                  onChange={(e) =>
                    handleInputForm("m", "price", e.target.value)
                  }
                />
              </div>
            </div>
            <div style={{ border: "1px solid grey" }}>
              <div>
                <h5 style={{ textAlign: "center" }}>L</h5>
                <label className={stl.text}>Кол-во цветков</label>
                <input
                  className={stl.inp}
                  name="l-quantity"
                  type="number"
                  value={formData.l.quantity}
                  onChange={(e) =>
                    handleInputForm("l", "quantity", e.target.value)
                  }
                />
              </div>
              <div>
                <label className={stl.text}>Цена</label>
                <input
                  className={stl.inp}
                  name="l-price"
                  type="number"
                  value={formData.l.price}
                  onChange={(e) =>
                    handleInputForm("l", "price", e.target.value)
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
};

export default Input;
