import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createTarif,
  deleteTarif,
  editTarif,
  loadTarif,
  loadZone,
} from "../../../../../app/http/delivery";
import stl from "./tarifs.module.scss";
// import data from ".. ./static/json/data.geojson";
// import data from "../../../../../../public/json/data.geojson";
import MapComponent from "../../../../../features/YandexMaps/MapComponent";
import Suggest from "../../../../../features/YandexMaps/Suggest";
import GeoYandex from "../../../../../features/YandexMaps/GeoYandex_archiv";
import FileUpload from "../geojson/FileUpload";
import YON from "../../../../../shared/ui/yesorno/Yon";
import Modal from "../../../../../shared/ui/modal/Modal";
import YandexDeliveryCopy from "../../../../../features/YandexMaps/YandexDelivery copy";

const Tarifs = () => {
  const dispatch = useDispatch();
  // const zonesData = data.features.map(feature => feature.properties.description);
  const [zone, setZone] = useState({
    name: "",
    cat: "0",
    price: "",
    startTime: "",
    endTime: "",
  });
  const [del, setDel] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [editedTarifs, setEditedTarifs] = useState({});
  const tarifs = useSelector((state) => state.delivery?.tarifs);
  const zones = useSelector((state) => state.delivery?.zones);
  const [cat, setCat] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setZone((prevZone) => ({
      ...prevZone,
      [name]: value,
    }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(createTarif(zone));
  };
  const handleDelete = (e) => {
    // e.preventDefault();
    setDel(e);
    setModalActive(true);
    console.log(e, "что удаляем");
    // dispatch(createTarif(zone));
  };
  const funcDelete = () => {
    dispatch(deleteTarif(del));
  };

  const handleEditPriceChange = (tarifId, field, value) => {
    setEditedTarifs((prevEditedTarifs) => ({
      ...prevEditedTarifs,
      [tarifId]: { ...prevEditedTarifs[tarifId], [field]: value },
    }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    dispatch(editTarif(editedTarifs));
  };

  useEffect(() => {
    dispatch(loadZone());
  }, []);
  useEffect(() => {
    if (zones && zones?.features && zones?.features?.length > 0) {
      setCat(zones.features[0].id);
    }
  }, [zones]);
  useEffect(() => {
    if (cat) {
      dispatch(loadTarif(cat));
      setZone((prevZone) => ({
        ...prevZone,
        cat: cat,
      }));
      console.log(zones, "что там в зонах?????7");
    }
  }, [cat]);
  useEffect(() => {
    dispatch(loadTarif(0));
  }, []);

  function handleSelectionChange(event) {
    console.log(event, "что в логах?");
    setCat(event.target.value);
  }

  return (
    <div className={stl.wrapper}>
      <Modal visible={modalActive} setVisible={setModalActive}>
        {modalActive && (
          <YON
            del={funcDelete}
            text={"Вы точно хотите удалить данный тариф?"}
            setVisible={setModalActive}
          />
        )}
      </Modal>
      <FileUpload />
      {/* <select>
        {zones.map((zone, index) => (
          <option key={index} value={zone.name}>
            {zone.name}
          </option>
        ))}
      </select> */}
      Зона доставки:{" "}
      <select className={stl.select} onChange={handleSelectionChange}>
        {zones &&
          zones?.features?.map((feature, index) => (
            <option key={index} value={feature.id}>
              {feature.properties.description}
            </option>
          ))}
      </select>
      <h1 style={{ textAlign: "center" }}>Тарифы</h1>
      <div className={stl.inputs}>
        <input
          className={stl.inp}
          type="text"
          name="name"
          value={zone.name}
          onChange={handleChange}
          placeholder="Название тарифа"
        />
        <input
          className={stl.inp}
          type="number"
          name="price"
          value={zone.price}
          onChange={handleChange}
          placeholder="Стоимость тарифа"
        />
        от
        <input
          className={stl.inp}
          type="time"
          name="startTime"
          value={zone.startTime}
          onChange={handleChange}
          placeholder="Время начала"
        />
        до
        <input
          className={stl.inp}
          type="time"
          name="endTime"
          value={zone.endTime}
          onChange={handleChange}
          placeholder="Время окончания"
        />
        <button
          className={stl.create}
          disabled={
            !zone.name || !zone.price || !zone.startTime || !zone.endTime
          }
          onClick={handleClick}>
          Создать
        </button>
      </div>
      <div className={stl.tarifs}>
        {tarifs &&
          tarifs.length > 0 &&
          [...tarifs]
            .sort((a, b) => a.id - b.id)
            .map((tarif) => (
              <div className={stl.unit} key={tarif.id}>
                <p className={stl.name}>{tarif.name}</p>
                <div className={stl.price}>
                  <input
                    type="number"
                    value={editedTarifs[tarif.id]?.price || tarif.price}
                    onChange={(e) =>
                      handleEditPriceChange(tarif.id, "price", e.target.value)
                    }
                    step={10}
                    className={stl.elem}
                  />
                  <p style={{ textAlign: "center" }}>Руб</p>
                </div>
                <div className={stl.time}>
                  <input
                    type="time"
                    value={editedTarifs[tarif.id]?.startTime || tarif.startTime}
                    onChange={(e) =>
                      handleEditPriceChange(
                        tarif.id,
                        "startTime",
                        e.target.value
                      )
                    }
                    className={stl.elem}
                  />
                  <input
                    type="time"
                    value={editedTarifs[tarif.id]?.endTime || tarif.endTime}
                    onChange={(e) =>
                      handleEditPriceChange(tarif.id, "endTime", e.target.value)
                    }
                    className={stl.elem}
                  />
                  <button
                    className={stl.del}
                    onClick={() => handleDelete(tarif.id)}>
                    Удалить
                  </button>
                </div>
              </div>
            ))}
      </div>
      <button
        disabled={Object.keys(editedTarifs).length === 0}
        onClick={handleSave}
        className={stl.btn}>
        Сохранить
      </button>
      {/* <Suggest /> */}
      {/* <MapComponent /> */}
      <YandexDeliveryCopy />
    </div>
  );
};

export default Tarifs;
