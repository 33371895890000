import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../app/http/userAPI.js';
import { setAuth } from '../../app/redux/store/userStore/authSlice.js';
import { setUser } from '../../app/redux/store/userStore/userSlice.js';
import { ADMIN_ROUTE } from '../../app/router/utils/consts.js';
import stl from './auth.module.scss';

const Login = () => {
    const dispatch = useDispatch();
    // const isAuth = useSelector(state => state.isAuth);
    const navigate = useNavigate();
    // const user = useSelector(state => state.user);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    // const toggleIsAuth = () => dispatch(setAuth(true));

    const click = async () => {
        try {
            const data = await login(email, password);
            dispatch(setUser(data));
            dispatch(setAuth(true));
            navigate(ADMIN_ROUTE)
        }
        catch (e) {
            alert(e.response.data.message)
        }
    }

    return (
        <div className={stl.wrapper}>
            <div className={stl.form}>
                <h2>Авторизация</h2>
                <form action="">
                    <input
                        className={stl.inp}
                        type="text"
                        placeholder='Введите email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}

                    /><br></br>
                    <input
                        className={stl.inp}
                        type='password'
                        placeholder='Введите пароль'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                </form>
                <button className={stl.btn} onClick={click} >Войти</button>
            </div>

        </div>
    );
};

export default Login;