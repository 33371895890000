import React, { useEffect, useRef, useState } from "react";
import {
  YMaps,
  Map,
  GeoObject,
  GeolocationControl,
  SearchControl,
  Placemark,
} from "@pbe/react-yandex-maps";
import axios from "axios"; // Подключаем библиотеку axios для выполнения запросов
import Suggest from "./Suggest";
import stl from "./yandexDelivery.module.scss";
import { useDispatch } from "react-redux";

const YandexDelivery = ({ placemark, delivery, addressCurrent, time }) => {
  const dispatch = useDispatch();
  const map = useRef(null);
  const node = useRef(null);
  const polys = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [zone, setZone] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // const [selectedCoords, setSelectedCoords] = useState(null);
  const [selectedCoords, setSelectedCoords] = useState([]);
  const [mapState, setMapState] = useState({
    center: [46.00461899270041, 51.556056709891905], // Саратов, Россия
    zoom: 10,
  });
  // const API_KEY = "05f8d2ae-bd94-4329-b9f9-7351e2ec9627";
  // const API_KEY = "f1329419-06d8-42ca-8625-d8325ea5d2df";
  const API_KEY = "ea129ffc-f236-4015-8457-14b3f9a81022";

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Выполняем запрос к файлу data.geojson, предполагая, что он находится в папке json
        const response = await axios.get("/json/saratov.geojson");
        const json = response.data;
        setPolygons(json); // Сохраняем полигоны в состояние
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    };

    fetchData(); // Вызываем функцию для выполнения запроса при монтировании компонента
  }, []); // Запускаем только один раз при монтировании

  useEffect(() => {
    const fetchCoords = async () => {
      try {
        const response = await axios.get(
          `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${selectedAddress}`
        );
        // const big = response.data;

        const coordinates =
          response?.data?.response?.GeoObjectCollection?.featureMember[0]
            ?.GeoObject.Point.pos;
        if (coordinates) {
          const [longitude, latitude] = coordinates.split(" ").map(parseFloat);
          setSelectedCoords([longitude, latitude]);
          setMapState({
            center: [longitude, latitude],
            zoom: 17, // Увеличиваем масштаб при выборе нового адреса
          });
        } else {
          console.error("Координаты не найдены");
        }
      } catch (error) {
        console.error("Ошибка получения координат:", error);
      }
    };

    if (selectedAddress) {
      fetchCoords();
    }
  }, [selectedAddress]);

  useEffect(() => {
    const checkPolygonContainment = (coords) => {
      // console.log("тест");
      if (polygons && mapInstance) {
        // console.log("тест");
        // const geoQuery = mapInstance?.geoQuery(polygons)?.addToMap(map.current);
        const geoQuery = mapInstance?.geoQuery(polygons)?.addToMap(map.current);
        geoQuery.each(function (obj) {
          obj.options.set({
            fillOpacity: 0,
            strokeWidth: 0,
            // draggable: true,
            // editorMenuManager: true,
            // editorDrawingCursor: "crosshair",
            // strokeWidth: obj.properties.get("stroke-width"),
            // далее раскоментить
            // fillColor: obj.properties.get("fill"),
            // fillOpacity: obj.properties.get("fill-opacity"),
            // strokeColor: obj.properties.get("stroke"),
            // strokeWidth: 2,
            // strokeOpacity: obj.properties.get("stroke-opacity"),
          });
          obj.properties.set(
            "balloonContent",
            obj.properties.get("description")
          );
        });

        const result = geoQuery?.searchContaining(coords)?.get(0);
        console.log(result, "Результат поиска вхождения");
        if (coords?.length > 0 && selectedAddress) {
          if (result !== undefined) {
            // setZone(result?.properties?._data?.description);
            addressCurrent({
              address: selectedAddress,
              zone: result?.properties?._data?.description,
              availability: true,
            });
            // setZone(result?.id);
            // console.log();
          } else {
            addressCurrent({
              address: selectedAddress,
              zone: result?.properties?._data?.description,
              availability: false,
            });
            // setZone("Доставка не осуществляется");
          }
        }

        console.log(result?.properties?._data?.description, "результат");
      }
    };

    // Вызываем функцию для проверки вхождения точки в полигоны при изменении координат
    if (mapInstance && polygons.features?.length > 0) {
      checkPolygonContainment(selectedCoords);
    }
  }, [selectedCoords, mapInstance, polygons]);

  const handleAddressSelect = (event) => {
    // const coords = event.get("coords");
    console.log(event, "что с адресом?");
    const selectedAddressOut = event?.formatted_address;
    setSelectedAddress(selectedAddressOut);
  };

  useEffect(() => {
    if (placemark === "Самовывоз") {
      setSelectedCoords([46.039916, 51.541965]);
      setMapState({
        center: [46.039916, 51.541965],
        zoom: 17, // Увеличиваем масштаб при выборе нового адреса
      });
    }
    // if (placemark === "г.Энгельс, ул. Покровская, д.11") {
    //   setSelectedCoords([46.040248, 51.386924]);
    //   setMapState({
    //     center: [46.040248, 51.386924],
    //     zoom: 17, // Увеличиваем масштаб при выборе нового адреса
    //   });
    // }
  }, [placemark]);
  // useEffect(() => {
  //   if (possibility) {
  //     dispatch();
  //   }
  // }, [possibility]);

  return (
    <div className={stl.wrap}>
      <div
        style={{
          position: "relative",
          //  minHeight: "60px"
        }}>
        {delivery && time && placemark !== "Самовывоз" ? (
          <Suggest onAddressSelect={handleAddressSelect} />
        ) : placemark === "Самовывоз" ? null : (
          <p style={{ color: "red", fontSize: "18px", margin: "0" }}>
            Для поиска адреса выберите дату и время доставки
          </p>
        )}
      </div>
      <div className={stl.mapContainer}>
        <YMaps
          query={{
            apikey: API_KEY,
            coordorder: "longlat",
            // , load: "package.full"
          }}
          // query={{
          //   lang: "ru_RU",
          //   apikey: API_KEY,
          // }}>
        >
          <Map
            instanceRef={map}
            onLoad={(ymapsInstance) => {
              setMapInstance(ymapsInstance);
            }}
            modules={[
              "geoQuery",
              "control.ZoomControl",
              "control.FullscreenControl",
            ]}
            // options={{ searchControlProvider: "yandex#search" }}
            // width="100%" // Используем динамическую ширину
            // height="300px" // Используем динамическую высоту
            style={{ maxWidth: "100%", height: "100%" }}
            // defaultState={{
            //   center: [46.00461899270041, 51.556056709891905],
            //   zoom: 9,
            // }}
            state={mapState}>
            {/* {polygons?.features?.map((feature, index) => (
            <GeoObject
              instanceRef={polys}
              key={index}
              geometry={feature.geometry}
              properties={{ balloonContent: feature.properties.description }}
              options={{
                // draggable: true,
                fillColor: feature.properties.fill, // Цвет заливки
                fillOpacity: feature.properties["fill-opacity"], // Прозрачность заливки
                strokeColor: feature.properties.stroke, // Цвет обводки
                strokeWidth: feature.properties["stroke-width"], // Толщина обводки
                strokeOpacity: feature.properties["stroke-opacity"], // Прозрачность обводки
                editorDrawingCursor: "crosshair",
                editorMaxPoints: 5,
                editorMenuManager: true,
              }}
            />
          ))} */}
            {selectedCoords && (
              <Placemark
                modules={["geoObject.addon.balloon"]}
                instanceRef={node}
                geometry={selectedCoords}
              />
            )}
            {placemark && (
              <Placemark
                modules={["geoObject.addon.balloon"]}
                instanceRef={node}
                geometry={selectedCoords}
              />
            )}
            <GeolocationControl options={{ float: "left" }} />
            {/* <SearchControl options={{ float: "right" }} /> */}
          </Map>
        </YMaps>
      </div>
    </div>
  );
};

export default YandexDelivery;
