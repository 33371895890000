import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOptionPrices,
  updateTotalPrice,
} from "../../app/redux/store/totalPriceStore/totalPriceSlice";
import { setModel } from "../../app/redux/store/ImageConfigure/ImageConfigureSlice";
import { loadImageByOption } from "../../app/http/ImageConfigureApi";
import { selectAdvancedOptions } from "../../app/redux/store/optionSelectStore/optionSelectSlice";
import stl from "./scss/OptionsDeviceFrontAdvanced.module.scss";

const OptionsDeviceFrontAdvanced = () => {
  const dispatch = useDispatch();
  const optionsSelect = useSelector(selectAdvancedOptions);
  const [selectedOption, setSelectedOption] = useState(null);
  const limitSquare = useSelector((state) => state.totalPrice.limitSquare);
  const standAloneOptions = useSelector(
    (state) => state.optionsSelect?.options?.options
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);

  // const handleSelectChange = (event) => {
  //   const selectedValue = event.target.value;
  //   const selectedOption = optionsSelect[0]?.options.find(
  //     (option) => option.priceChange === +selectedValue
  //   );

  //   setSelectedOption(selectedOption);
  //   // console.log(optionsSelect[0], "проверка на ЧЕКЕД!!!!!!!!!!");

  //   dispatch(
  //     updateTotalPrice({
  //       price: +selectedValue,
  //       name: optionsSelect[0].name,
  //       nameOptionValue: selectedOption.name,
  //       // nameOptionValue: optionsSelect[0]?.name,
  //     })
  //   );

  //   // dispatch(loadImageByOption(selectedOptions));
  // };

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = standAloneOptions.find(
      (option) => option.id === selectedValue
    );
    console.log(event.target.checked, "проверка на ЧЕКЕД!!!!!!!!!!");
    dispatch(
      updateTotalPrice({
        price: selectedOption.priceChange,
        name: selectedOption.name,
        nameOptionValue: selectedOption.name,
        checked: event.target.checked,
      })
    );
    // setActiveTooltip(option.about);
    // setSelectedOptions((prevOptions) => {
    //   if (prevOptions.includes(optionId)) {
    //     // Если опция уже выбрана, уберем ее из списка
    //     return prevOptions.filter((id) => id !== optionId);
    //   } else {
    //     // Если опция не выбрана, добавим ее в список
    //     return [...prevOptions, optionId];
    //   }
    // });
  };
  useEffect(() => {
    dispatch(resetOptionPrices());
    // setSelectedOptions([]);
    // setActiveTooltip(null);
  }, []);

  return (
    <div style={{ marginTop: "1rem" }}>
      {/* <label className={stl.header} htmlFor="typeSelect">
        Опции
      </label> */}
      {/* <select
        onChange={handleSelectChange}
        className={stl.select}
        value={selectedOption?.priceChange || "0"}>
        <option value="0">без автоматики</option>
        {optionsSelect[0]?.options
          .filter((option) => option.limitSquare > limitSquare)
          .map((option) => (
            <option
              className={stl.options}
              key={option.id}
              value={option.priceChange}>
              {option.name}
            </option>
          ))}
      </select> */}
      {/* <label htmlFor="name">Набор из checkbox</label> */}
      <ul className={stl.checkbox}>
        {standAloneOptions?.map((option) => (
          <li key={option.id} className={stl.li}>
            <input
              id={`checked_${option.id}`}
              className={stl.checkbox_input}
              type="checkbox"
              // title={option.name}
              onChange={(e) => handleOptionChange(e)}
              value={option.id}
              // className={style.input}
              // onChange={handleOptionChange}
            />

            <label
              htmlFor={`checked_${option.id}`}
              className={stl.checkbox_label}></label>
            <span
              className={stl.span}
              onMouseEnter={() => setActiveTooltip(option)}
              onMouseLeave={() => setActiveTooltip(null)}>
              {option.name}
              {activeTooltip && (
                <div className={stl.tooltip}>
                  {activeTooltip.tarea}{" "}
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "./options/" +
                      activeTooltip?.imageUrl
                    }
                    className={stl.img}
                    alt={activeTooltip?.name}
                  />
                </div>
              )}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OptionsDeviceFrontAdvanced;
