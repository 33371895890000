import React, { useEffect, useState } from "react";
import stl from "./galery.module.scss";
import { ReactComponent as SvgEmpty } from "./svg/image.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";

const GaleryFront = (props) => {
  const dimensions = useSelector(
    (state) => state.totalPrice?.dimensions?.height
  );
  const [currentImg, setCurrentImg] = useState(0);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 50 });
  const [isHovered, setIsHovered] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:768px)");

  const handleClick = (index) => {
    setCurrentImg(index);
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setCursorPosition({ x, y });
  };

  const handleMouseLeave = () => {
    setCursorPosition({ x: 0, y: 50 });
    // setZoomLevel(1);
    setIsHovered(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    setZoomLevel(1.7); // Увеличиваем уровень зума при наведении
  };

  useEffect(() => {
    if (dimensions) {
      setCurrentImg(0);
    }
  }, [dimensions]);
  return (
    <div className={stl.wrapper}>
      {props.images && props.images.length > 0 ? (
        <>
          {isMobile ? (
            <>
              <div className={stl.imgwrapmobile}>
                <img
                  className={stl.bigimg}
                  src={
                    process.env.REACT_APP_API_URL +
                    "images/products/" +
                    props.images[currentImg]?.mobileFull
                  }
                  alt=""
                />
                {(props.images[currentImg]?.quantity > 1 ||
                  props.images[currentImg]?.height > 1) && (
                  <span className={stl.qnt}>
                    {props.images[currentImg]?.quantity > 1 && (
                      <div>{props.images[currentImg]?.quantity} шт</div>
                    )}
                    {props.images[currentImg]?.height > 1 && (
                      <div>{props.images[currentImg]?.height} см</div>
                    )}
                  </span>
                )}
              </div>
            </>
          ) : (
            <div
              className={stl.bigimg}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_API_URL +
                  "images/products/" +
                  props.images[currentImg]?.desktopFull
                })`,
                backgroundPosition: `${cursorPosition.x}% ${cursorPosition.y}%`,
                // backgroundSize: isHovered ? "cover" : "auto",
                // backgroundSize: isHovered ? "auto" : "cover",
                backgroundSize: isHovered ? `${100 * zoomLevel}%` : "cover",
                backgroundRepeat: "no-repeat",
                // backgroundSize: "cover",
                // transform: `scale(${zoomLevel})`, // Увеличиваем размер при наведении
                // overflow: "hidden", // Скрываем часть изображения, которая выходит за пределы контейнера
              }}>
              {(props.images[currentImg]?.quantity > 1 ||
                props.images[currentImg]?.height > 1) && (
                <span className={stl.qnt}>
                  {props.images[currentImg]?.quantity > 1 && (
                    <div>{props.images[currentImg]?.quantity} шт</div>
                  )}
                  {props.images[currentImg]?.height > 1 && (
                    <div>{props.images[currentImg]?.height} см</div>
                  )}
                </span>
              )}
            </div>
          )}

          <div className={stl.nav}>
            {props.images.length > 0 &&
              props.images.map((item, index) => (
                <img
                  key={index}
                  className={stl.img}
                  onClick={() => handleClick(index)}
                  src={
                    process.env.REACT_APP_API_URL +
                    "images/products/" +
                    item?.mini
                  }
                  alt=""
                />
              ))}
          </div>
          {/* <img
            className={stl.bigimg}
            src={process.env.REACT_APP_API_URL + props.images[currentImg]?.img}
            alt=""
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            // onMouseEnter={handleMouseEnter}
          />
          <div
            className={stl.large}
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_API_URL + props.images[currentImg]?.img
              })`,
              backgroundPosition: `${cursorPosition.x}% ${cursorPosition.y}%`,
              display:
                cursorPosition.x === 0 && cursorPosition.y === 0
                  ? "none"
                  : "block",
            }}
          /> */}
        </>
      ) : (
        <div className={stl.bigimg}>
          <SvgEmpty className={stl.svgimage} />
        </div>
      )}
    </div>
  );
};

export default GaleryFront;
