import React, { useState } from "react";
import { useSelector } from "react-redux";
// import { ReactComponent as SvgCart } from "./cart.svg";
import stl from "./cartPopup.module.scss";

import { selectTotalQnt } from "../../app/redux/cartStore/cartSlice";
import Popup from "../../shared/ui/popup/Popup";
import Order from "../../entities/order/Order";
import { IoMdBasket } from "react-icons/io";
import { ReactComponent as SvgCart } from "../../static/cart.svg";
// import Popup from "<путь к файлу>/Popup.jsx";

const CartPopup = () => {
  const totalPrice = useSelector(selectTotalQnt);
  const [modalActive, setModalActive] = useState(false);

  return (
    <div className={stl.wrapper}>
      {/* <SvgCart onClick={() => setModalActive(true)} className={stl.svgcart} /> */}
      <SvgCart onClick={() => setModalActive(true)} className={stl.svgcart} />
      {totalPrice > 0 && (
        <div className={stl.centeredContent}>
          <div className={stl.qnt}>{totalPrice}</div>
          <div className={stl.block}></div>
        </div>
      )}
      <Popup visible={modalActive} setVisible={setModalActive}>
        <Order closeModal={() => setModalActive(false)} />
        {/* asfasfasfasf */}
      </Popup>
    </div>
  );
};

export default CartPopup;
