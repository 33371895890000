import React, { useState } from "react";
import Card from "../../../widgets/product-card/Card";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import styles from "./bouquets.module.scss";
import {
  selectAllDevices,
  sortDevicesByPrice,
} from "../../../app/redux/store/deviceStore/deviceSlice";
import {
  loadDeviceByUrl,
  loadDevicesToType,
} from "../../../app/http/deviceApi";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  selectAllTypes,
  selectTypeById,
  setSelectedId,
} from "../../../app/redux/store/typeStore/typeSlice";
import { BOUQUET, DEVICE_ROUTE } from "../../../app/router/utils/consts";

//импорты для Lexical
import { $getRoot, $getSelection } from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

const Bouquets = () => {
  const params = useParams();
  const id = params.name;
  // const pathname = location.pathname;
  // const id = pathname.substring(pathname.lastIndexOf("/") + 1);
  console.log(id, "адрес проверь");
  // const [selectedId, setSelectedId] = useState(null);
  // const selectedId = useSelector((state) => state.type.selectedId);
  const selectedType = useSelector((state) => selectTypeById(state, id));
  // const types = useSelector(selectAllTypes);
  console.log(selectedType, "selectTYYYYYYYPEEEEE");
  const dispatch = useDispatch();
  const devices = useSelector(selectAllDevices);
  const [sortOption, setSortOption] = useState("asc");

  // useEffect(() => {
  //   if (selectedId) dispatch(loadDevicesToType(selectedId)); // Сбросить selectedId при монтировании компонента
  // }, [selectedId, dispatch]);
  useEffect(() => {
    if (id) dispatch(loadDevicesToType(id)); // Сбросить selectedId при монтировании компонента
  }, [id, dispatch]);
  // useEffect(() => {
  //   if (id) dispatch(loadDeviceByUrl(id)); // Сбросить selectedId при монтировании компонента
  // }, [id, dispatch]);

  const handleSortOptionChange = (e) => {
    setSortOption(e.target.value);
  };
  // const handleLinkClick = (id) => {
  //   dispatch(setSelectedId({ id }));
  // };
  const handleLinkClick = (id) => {
    setSelectedId(id);
  };

  useEffect(() => {
    dispatch(sortDevicesByPrice(sortOption));
  }, [sortOption, dispatch]);

  let content = null;

  if (devices.length > 0) {
    content = devices.map((device, index) => (
      // <div key={device.id} className={styles.wrapper}>
      <Card key={device.id} {...device} />
      // </div>
    ));
  } else {
    if (selectedType !== null)
      // если товаров в категории нет, то отображаем список подкатегорий
      content = (
        <>
          <h1 className={styles.categoryTitle}>Выберите категорию</h1>
          {selectedType.map((type) => (
            <div className={styles.category}>
              <Link
                to={`/category/${type.id}`}
                onClick={() => handleLinkClick(type.id)}>
                <div className={styles.imageWrapper}>
                  {type.imageUrl ? (
                    <img
                      className={styles.typeimg}
                      src={process.env.REACT_APP_API_URL + type?.imageUrl}
                      alt=""
                    />
                  ) : (
                    <div className={styles.gray_background}></div>
                  )}
                  <div className={styles.caption}>
                    <h2 className={styles.captionText}>{type.name}</h2>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </>
      );
    else {
      content = (
        <p className={styles.wrapper}>Нет доступных товаров и подкатегорий.;</p>
      );
    }
  }
  // const item =
  //   devices.length > 0
  //     ? devices.map((device, index) => (
  //         <div key={device.id} className={styles.wrapper}>
  //           <Card {...device} />
  //         </div>
  //       ))
  //     : null;

  return (
    <>
      <div className={styles.sortOption}>
        {/* <label htmlFor="sortOption">Sort by:</label> */}
        <select
          id="sortOption"
          value={sortOption}
          onChange={handleSortOptionChange}>
          <option value="asc">сначала дешевле</option>
          <option value="desc">сначала дороже</option>
        </select>
      </div>
      <div className={styles.wrapper}>{content}</div>
      {/* <div>{content}</div> */}
    </>
  );
};

export default Bouquets;
