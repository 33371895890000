import React, { useEffect, useRef, useState } from "react";
import {
  YMaps,
  Map,
  GeoObject,
  GeolocationControl,
  SearchControl,
  Placemark,
} from "@pbe/react-yandex-maps";
import axios from "axios"; // Подключаем библиотеку axios для выполнения запросов
import Suggest from "./Suggest";
import stl from "./yandexDelivery.module.scss";

const YandexDeliveryCopy = () => {
  const map = useRef(null);
  const node = useRef(null);
  const polys = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  // const [selectedCoords, setSelectedCoords] = useState(null);
  const [selectedCoords, setSelectedCoords] = useState([]);
  const [mapState, setMapState] = useState({
    center: [46.00461899270041, 51.556056709891905], // Саратов, Россия
    zoom: 10,
  });
  // const API_KEY = "05f8d2ae-bd94-4329-b9f9-7351e2ec9627";
  // const API_KEY = "f1329419-06d8-42ca-8625-d8325ea5d2df";
  const API_KEY = "ea129ffc-f236-4015-8457-14b3f9a81022";

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Выполняем запрос к файлу data.geojson, предполагая, что он находится в папке json
        const response = await axios.get("/json/saratov.geojson");
        const json = response.data;
        setPolygons(json); // Сохраняем полигоны в состояние
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
    };

    fetchData(); // Вызываем функцию для выполнения запроса при монтировании компонента
  }, []); // Запускаем только один раз при монтировании

  useEffect(() => {
    const fetchCoords = async () => {
      try {
        const response = await axios.get(
          `https://geocode-maps.yandex.ru/1.x/?apikey=${API_KEY}&format=json&geocode=${selectedAddress}`
        );
        // const big = response.data;

        const coordinates =
          response?.data?.response?.GeoObjectCollection?.featureMember[0]
            ?.GeoObject.Point.pos;
        if (coordinates) {
          const [longitude, latitude] = coordinates.split(" ").map(parseFloat);
          setSelectedCoords([longitude, latitude]);
          setMapState({
            center: [longitude, latitude],
            zoom: 17, // Увеличиваем масштаб при выборе нового адреса
          });
        } else {
          console.error("Координаты не найдены");
        }
      } catch (error) {
        console.error("Ошибка получения координат:", error);
      }
    };

    if (selectedAddress) {
      fetchCoords();
    }
  }, [selectedAddress]);

  useEffect(() => {
    const checkPolygonContainment = (coords) => {
      // console.log("тест");
      if (polygons && mapInstance) {
        // console.log("тест");
        // const geoQuery = mapInstance?.geoQuery(polygons)?.addToMap(map.current);
        const geoQuery = mapInstance?.geoQuery(polygons).addToMap(map.current);
        geoQuery.each(function (obj) {
          obj.options.set({
            fillOpacity: 0,
            strokeWidth: 0,
            // draggable: true,
            // editorMenuManager: true,
            // editorDrawingCursor: "crosshair",
            // strokeWidth: obj.properties.get("stroke-width"),
            // далее раскоментить
            fillColor: obj.properties.get("fill"),
            fillOpacity: obj.properties.get("fill-opacity"),
            strokeColor: obj.properties.get("stroke"),
            strokeWidth: 2,
            strokeOpacity: obj.properties.get("stroke-opacity"),
          });
          obj.properties.set(
            "balloonContent",
            obj.properties.get("description")
          );
        });
        // console.log(geoQuery, "Результат поиска вхождения");
        const result = geoQuery?.searchContaining(coords)?.get(0);
        if (coords.length > 0) {
          if (result !== undefined) {
            alert(result?.properties?._data?.description);
          } else {
            alert("Доставка не осуществляется");
          }
        }

        console.log(result?.properties?._data?.description, "результат");
      }
    };

    // Вызываем функцию для проверки вхождения точки в полигоны при изменении координат
    if (mapInstance && polygons.features.length > 0) {
      checkPolygonContainment(selectedCoords);
    }
  }, [selectedCoords, mapInstance, polygons]);

  const handleAddressSelect = (event) => {
    // const coords = event.get("coords");
    setSelectedAddress(event?.formatted_address);
  };

  return (
    <div className={stl.wrap}>
      <Suggest onAddressSelect={handleAddressSelect} />

      <YMaps
        query={{
          apikey: API_KEY,
          coordorder: "longlat",
          // , load: "package.full"
        }}
        // query={{
        //   lang: "ru_RU",
        //   apikey: API_KEY,
        // }}>
      >
        <Map
          instanceRef={map}
          onLoad={(ymapsInstance) => {
            setMapInstance(ymapsInstance);
          }}
          modules={[
            "geoQuery",
            "control.ZoomControl",
            "control.FullscreenControl",
          ]}
          // options={{ searchControlProvider: "yandex#search" }}
          width="700px"
          height="400px"
          // defaultState={{
          //   center: [46.00461899270041, 51.556056709891905],
          //   zoom: 9,
          // }}
          state={mapState}>
          {/* {polygons?.features?.map((feature, index) => (
            <GeoObject
              instanceRef={polys}
              key={index}
              geometry={feature.geometry}
              properties={{ balloonContent: feature.properties.description }}
              options={{
                // draggable: true,
                fillColor: feature.properties.fill, // Цвет заливки
                fillOpacity: feature.properties["fill-opacity"], // Прозрачность заливки
                strokeColor: feature.properties.stroke, // Цвет обводки
                strokeWidth: feature.properties["stroke-width"], // Толщина обводки
                strokeOpacity: feature.properties["stroke-opacity"], // Прозрачность обводки
                editorDrawingCursor: "crosshair",
                editorMaxPoints: 5,
                editorMenuManager: true,
              }}
            />
          ))} */}
          {selectedCoords && (
            <Placemark
              modules={["geoObject.addon.balloon"]}
              instanceRef={node}
              geometry={selectedCoords}
            />
          )}
          <GeolocationControl options={{ float: "left" }} />
          {/* <SearchControl options={{ float: "right" }} /> */}
        </Map>
      </YMaps>
    </div>
  );
};

export default YandexDeliveryCopy;
