import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { loadOptions } from "../../app/http/optionApi";
import stl from "./input.module.scss";
import {
  addOptionDevice,
  createDeviceOption,
  loadOptionsSelect,
  loadOptionsSelectDevice,
} from "../../app/http/optionSelectApi";

const OptionsSelect = (props) => {
  const dispatch = useDispatch();
  const [deviceId, setDeviceId] = useState(props.device);
  const [selectedOptions, setSelectedOptions] = useState({
    optional: [],
    optionalList: [],
  });
  const options = useSelector((state) => state.optionsSelect.list);
  const optionstatus = useSelector((state) => state.optionsSelect);
  const optionsSelect = useSelector((state) => state.optionsSelect.options);

  useEffect(() => {
    dispatch(loadOptionsSelect());
  }, []);

  const handleOptionAlone = (optionId) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      if (newOptions.optional.includes(optionId)) {
        // Если опция уже выбрана, уберем ее из списка
        newOptions.optional = newOptions.optional.filter(
          (id) => id !== optionId
        );
      } else {
        // Если опция не выбрана, добавим ее в список
        newOptions.optional = [...newOptions.optional, optionId];
      }
      return newOptions;
    });
  };

  const handleOptionList = (optionId) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      if (newOptions.optionalList.includes(optionId)) {
        // Если опция уже выбрана, уберем ее из списка
        newOptions.optionalList = newOptions.optionalList.filter(
          (id) => id !== optionId
        );
      } else {
        // Если опция не выбрана, добавим ее в список
        newOptions.optionalList = [...newOptions.optionalList, optionId];
      }
      return newOptions;
    });
  };
  const handleSaveOptions = () => {
    const payload = {
      selectedOptions,
      deviceId,
    };

    dispatch(createDeviceOption(payload));

    // Ваш код для сохранения выбранных опций
    // console.log("Сохранены опции:", selectedOptions, props.device);
  };

  useEffect(() => {
    // Обновляем deviceId при изменении props.device
    setDeviceId(props.device);
  }, []);

  useEffect(() => {
    // dispatch(loadOptionsSelectDevice());
    // // const deviceId = props.device;
    // setDeviceId(props.device);
    const deviceOptions = optionsSelect[deviceId];

    if (deviceOptions) {
      setSelectedOptions({
        optional: deviceOptions.options.map((option) => option.id) || [],
        optionalList:
          deviceOptions.optionLists.map((optionList) => optionList.id) || [],
      });
    }
  }, [
    optionsSelect,
    // options,
    // , props.device
  ]);

  return (
    <div className={stl.optionsSelect}>
      {optionstatus.status === "loading" && (
        <p style={{ color: "orangered" }}>Загрузка...</p>
      )}
      {optionstatus.status === "failed" && (
        <p style={{ color: "red" }}>Error: {optionstatus.error}</p>
      )}
      {optionstatus.status === "succeeded" && (
        <p style={{ color: "green" }}>Успешно</p>
      )}
      <p>Выбор опций</p>
      {/* <ul>
        {options.map((option) => (
          <li key={option.id}>
            <label htmlFor="Опция">
              {option.name}

              <input
                type="checkbox"
                value={option.id}
                className={stl.input}
                onChange={() => handleOptionChange(option.id)}
              />
            </label>
          </li>
    ))}
      </ul> */}
      {options.standaloneOptions && options.standaloneOptions.length > 0 && (
        <>
          <p>Одиночные опции</p>
          {options.standaloneOptions.map((option) => (
            <div key={option.id}>
              <label htmlFor={`option_${option.id}`}>
                {option.name}
                <input
                  type="checkbox"
                  id={`option_${option.id}`}
                  value={option.id}
                  className={stl.input}
                  onChange={() => handleOptionAlone(option.id)}
                  checked={selectedOptions.optional.includes(option.id)}
                />
              </label>
            </div>
          ))}
        </>
      )}
      {options.allOptionLists && options.allOptionLists.length > 0 && (
        <>
          <p>Наборы опций</p>
          {options.allOptionLists
            .filter((list) => list.imageUrl === null) // Фильтруем опции, где imageUrl равно null
            .map((list) => (
              <div key={list.id}>
                <label htmlFor={`option_${list.id}`}>
                  {list.name}
                  <input
                    type="checkbox"
                    id={`list_${list.id}`}
                    value={list.id}
                    className={stl.input}
                    onChange={() => handleOptionList(list.id)}
                    checked={selectedOptions.optionalList.includes(list.id)}
                  />
                </label>
              </div>
            ))}
        </>
      )}
      <button onClick={handleSaveOptions}>Сохранить</button>
    </div>
  );
};

export default OptionsSelect;
