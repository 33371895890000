import React from "react";
import { useSelector } from "react-redux";

import { Routes, Route } from "react-router-dom";
import Bouquets from "../../pages/products/bouquets/Bouquets";
import Footer from "../../shared/ui/footer/Footer";
import Header from "../../shared/ui/header/Header";
import { store } from "../redux/store/store";
import { authRoutes, publicRoutes } from "./routes";
import { ADMIN_ROUTE, BOUQUET, DEVICE_ROUTE } from "./utils/consts";
import { selectAllTypes } from "../redux/store/typeStore/typeSlice";
import AdminPanel from "../../pages/admin/AdminPanel";
import { selectAllDevices } from "../redux/store/deviceStore/deviceSlice";
import DeviceItem from "../../entities/product/DeviceItem";
import OrderBy from "../../entities/orderby/OrderBy";
import stl from "./approuter.module.scss";
import Breadcrumb from "../../shared/ui/breadcrumbs/Breadcrumb";
import Glavnaya from "../../shared/ui/glavnaya/Glavnaya";
import GateForm from "../../widgets/cities/gateForm/GateForm";
import SliderAdmin from "../../pages/admin/content/Outlet/SliderAdmin";
import OptionsAdmin from "../../pages/admin/content/Outlet/OptionsAdmin";
import Left from "../../pages/admin/navbar/Left";
import Content from "../../pages/admin/content/Content";
import Options from "../../pages/admin/options/Options";
import Slider from "../../pages/admin/slider/Slider";
import Dostavka from "../../pages/dostavka/Dostavka";
import Contacts from "../../pages/contacts/Contacts";
import MapComponent from "../../features/MapGood/MapComponent";
import Delivery from "../../pages/admin/content/delivery/Delivery";
import YandexDelivery from "../../features/YandexMaps/YandexDelivery";
import Orders from "../../pages/admin/orders/Orders";
import Confirm from "../../pages/confirm/Confirm";

const Text = () => {
  return <h1>Что-нибудь</h1>;
};

const AppRouter = () => {
  const isAuth = useSelector((state) => state.isAuth);

  const selectedCity = useSelector((state) => state.city.selectedCity);
  console.log(selectedCity, "выбранный город");

  const types = useSelector(selectAllTypes);
  // console.log(type);
  const devices = useSelector(selectAllDevices);
  // const dynamicPrefix = "/sdfsdfs";

  // console.log(type);

  return (
    <>
      <Header />
      {/* <Breadcrumb /> */}
      <div className={stl.wrapper}>
        <Routes>
          <Route path="/" element={<Glavnaya />} />
          <Route path="/dostavka" element={<Dostavka />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/confirm" element={<Confirm />} />
          {/* <Route path="/maps" element={<MapComponent />} /> */}

          <Route path="checkout" element={<OrderBy />} />
          {isAuth && (
            <Route path="admin" element={<AdminPanel />}>
              <Route index element={<Content />} />
              <Route path="slider" element={<Slider />} />
              <Route path="options" element={<Options />} />
              <Route path="deliveries" element={<Delivery />} />
              <Route path="openstreetmap" element={<YandexDelivery />} />
              <Route path="orders" element={<Orders />} />
              <Route path="*" element={<Content />} />
            </Route>
          )}
          {/* {isAuth &&
            authRoutes.map(({ path, Component }) => (
              <Route
                key={path}
                path={path}
                // path="/sdfsdf"
                // path={`/${selectedCity?.id}${path}`}
                element={<Component />}
              />
            ))} */}
          {publicRoutes.map(({ path, Component }) => (
            <Route
              key={path}
              // path={`${path}`}
              path={path}
              // path={`${selectedCity.url}/${path}`}
              element={<Component />}
            />
          ))}
          {/* </Route> */}
          {!isAuth && <Route path="/admin/" element={<Bouquets />} />}

          {/* {isAuth && <Route path="/admin/:id" element={<AdminPanel />}></Route>} */}
          {/* <Route path="/zamer" element={<GateForm />} /> */}
          <Route path="*" element={<Glavnaya />} />
        </Routes>
      </div>

      <Footer />
    </>
  );
};
export default AppRouter;
