import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOption } from "../../../app/http/optionApi";
import style from "./optionsModal.module.scss";
import { ReactComponent as SvgImage } from "./svg/image.svg";
import { addOptionList } from "../../../app/http/optionListApi";

const OptionModalList = ({ setVisible, variable }) => {
  // console.log(variable, "VARIABL!!!!!!!!!!!!!!");
  //   const [optionName, setOptionName] = useState("");
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [listCheck, setlistCheck] = useState("checkbox");
  const [showSign, setShowSign] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    selectedOptions: [],
    signMC: "",
    // priceChange: 0,
  });

  const fileInputRef = useRef(null);
  const handleSvgPlusClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(file);
  };

  const options = useSelector((state) => state.option.list); // Получаем все опции из state

  // Фильтруем опции по значению OptionalType равному "radio"
  // const OptionsSelect = options.map((option) => option);
  const radioOptions = options.filter(
    (option) => option.OptionalType === "radio"
  );
  // Фильтруем опции по значению OptionalType равному "radio"
  const checkboxOptions = options.filter(
    (option) => option.OptionalType === "checkbox"
  );

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "sign") {
      setShowSign(checked);
    }
  };
  // const handleOptionChange = (optionId) => {
  //   setSelectedOptions((prevOptions) => {
  //     if (prevOptions.includes(optionId)) {
  //       // Если опция уже выбрана, уберем ее из списка
  //       return prevOptions.filter((id) => id !== optionId);
  //     } else {
  //       // Если опция не выбрана, добавим ее в список
  //       return [...prevOptions, optionId];
  //     }
  //   });
  // };
  const handleOptionChange = (selectedOption) => {
    setSelectedOptions((prevOptions) => {
      const optionId = selectedOption.id;

      if (prevOptions.includes(optionId)) {
        // Если опция уже выбрана, уберем ее из списка
        return prevOptions.filter((id) => id !== optionId);
      } else {
        // Если опция не выбрана, добавим ее в список
        const { id, name } = selectedOption;
        return [...prevOptions, { id, name }];
      }
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!formData.name) {
      alert("Пожалуйста, введите название опции.");
      return;
    }
    const formDataToSubmit = new FormData();

    // Добавляем файл картинки в formData
    if (fileInputRef.current.files[0]) {
      formDataToSubmit.append("img", fileInputRef.current.files[0]);
    }

    // Добавляем введенные параметры в formData
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
    formDataToSubmit.append("selectedOptions", JSON.stringify(selectedOptions));
    // console.log(formDataToSubmit, "проверка сохранения опции");

    dispatch(addOptionList(formDataToSubmit));
    setVisible(false);
  };

  return (
    <div className={style.wrapper}>
      <p></p>
      <p>Список опций с типом {listCheck}</p>
      {variable !== "variable" && (
        <select
          name="OptionalType"
          onChange={(e) => setlistCheck(e.target.value)}>
          <option value="checkbox">Чекбоксы</option>
          <option value="radio">
            Радиокнопки(выбор только одного параметра)
          </option>
        </select>
      )}

      {variable === "variable" &&
        (selectedImage ? (
          <img src={selectedImage} className={style.option} alt="Загружено" />
        ) : (
          <SvgImage className={style.svg_plus} onClick={handleSvgPlusClick} />
        ))}

      {variable !== "variable" && listCheck === "checkbox" && (
        <>
          <label htmlFor="name">Набор из checkbox</label>
          <ul>
            {checkboxOptions.map((option) => (
              <li key={option.id}>
                <label htmlFor="Опция">
                  <input
                    type="checkbox"
                    value={option.id}
                    className={style.input}
                    onChange={() => handleOptionChange(option.id)}
                  />
                  {option.name}
                </label>
              </li>
            ))}
          </ul>
        </>
      )}

      {variable === "variable" && listCheck === "checkbox" && (
        <ul>
          {options.map((option) => (
            <li key={option.id}>
              <label htmlFor="Опция">
                <input
                  type="checkbox"
                  value={option.id}
                  className={style.input}
                  onChange={() => handleOptionChange(option.id)}
                />
                {option.name}
              </label>
            </li>
          ))}
        </ul>
      )}

      {variable !== "variable" && listCheck === "radio" && (
        <>
          <label htmlFor="name">Набор из радиокнопок</label>
          <ul>
            {radioOptions.map((option) => (
              <li key={option.id}>
                <label htmlFor="Опция">
                  <input
                    type="checkbox"
                    value={option.id}
                    className={style.input}
                    onChange={() => handleOptionChange(option)}
                  />
                  {option.name}
                </label>
              </li>
            ))}
          </ul>
        </>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <input
        type="text"
        name="name"
        placeholder="Название опции"
        onChange={handleChange}
      />
      {/* <label>
        <input
          type="checkbox"
          name="sign"
          // checked={formData.limitSquare}
          onChange={handleCheckboxChange}
        />
        Признак цвета или модели
      </label>
      {showSign && (
        <select name="signMC" onChange={handleChange}>
          <option value="Нет">Нет</option>

          <option value="Цвета">Цвета</option>
          <option value="Модели">Модели</option>
        </select>
      )} */}
      <button onClick={handleSave}>Создать</button>
    </div>
  );
};

export default OptionModalList;
