import { createSlice } from "@reduxjs/toolkit";
import {
  createTarif,
  createZone,
  deleteTarif,
  editTarif,
  loadTarif,
  loadTarifFromZone,
  loadZone,
} from "../../../http/delivery";

const initialState = {
  status: "idle",
  error: null,
  tarifs: [],
  price: "",
  zones: null,
  selectedDevice: null,
};

const deliverySlice = createSlice({
  name: "@@tarifs",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTarif.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadTarif.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadTarif.fulfilled, (state, action) => {
        state.status = "received";
        state.tarifs = action.payload.tarifs;
        //надо получить объект типа []
      })
      .addCase(loadZone.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadZone.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadZone.fulfilled, (state, action) => {
        state.status = "received";
        state.zones = action.payload;
        //надо получить объект типа []
      })
      .addCase(createTarif.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createTarif.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(createTarif.fulfilled, (state, action) => {
        state.status = "received";
        const { id, name, price, startTime, endTime, cat } =
          action.payload.zoneNew;
        state.tarifs.push({ id, name, price, startTime, endTime, cat });
        //надо получить объект типа []
      })
      .addCase(deleteTarif.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteTarif.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(deleteTarif.fulfilled, (state, action) => {
        state.status = "received";
        console.log(action.payload.tarifId, "что в пэйлоаде удаления тарифа");
        const id = +action.payload.tarifId;
        console.log(typeof id, "какой тип?");
        state.tarifs = state.tarifs.filter((c) => c.id !== id);
        // const { id, name, price, startTime, endTime, cat } =
        //   action.payload.zoneNew;
        // state.tarifs.push({ id, name, price, startTime, endTime, cat });
        //надо получить объект типа []
      })
      .addCase(editTarif.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(editTarif.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(editTarif.fulfilled, (state, action) => {
        state.status = "received";
        const updatedTarifs = action.payload; // Объект с обновлениями для тарифов
        console.log(updatedTarifs);
        Object.keys(updatedTarifs).forEach((id) => {
          const updatedFields = updatedTarifs[id]; // Обновленные поля для конкретного тарифа с данным id
          const index = state.tarifs.findIndex(
            (tarif) => tarif.id === parseInt(id)
          );
          if (index !== -1) {
            state.tarifs[index] = {
              ...state.tarifs[index], // Сохраняем все остальные поля без изменений
              ...updatedFields, // Обновляем только указанные поля
            };
          }
        });
      })
      .addCase(createZone.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createZone.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(createZone.fulfilled, (state, action) => {
        state.status = "received";
        const { id, name, deliveryCost } = action.payload;
        state.zones.push({ id, name, deliveryCost });
        //надо получить объект типа []
      })
      .addCase(loadTarifFromZone.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadTarifFromZone.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadTarifFromZone.fulfilled, (state, action) => {
        state.status = "received";
        state.price = action.payload.tarif.price;
        //надо получить объект типа []
      });
  },
});
export const { setPrice } = deliverySlice.actions;
export const deliveryReducer = deliverySlice.reducer;
