import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import stl from "./deliveryDateTime.module.scss";
import {
  subDays,
  addDays,
  eachDayOfInterval,
  setMinutes,
  setHours,
} from "date-fns";

// Регистрируем русскую локаль глобально
registerLocale("ru", ru);
// Устанавливаем русскую локаль по умолчанию
setDefaultLocale("ru");

const DeliveryDateTime = ({ deliveryOption, onTimeChange }) => {
  console.log(deliveryOption, "ДОСТАВКААААА");
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  // const initialSelectedDate =
  //   currentDate.getHours() < 10
  //     ? setHours(setMinutes(new Date(), 30), 10) // Если текущее время меньше 10:00, устанавливаем на 10:30
  //     : new Date(currentDate.getTime() + 90 * 60 * 1000); // В противном случае, устанавливаем через 90 минут от текущего времени
  let initialSelectedDate;

  if (currentHour < 9) {
    // Если текущее время меньше 10:00 или больше 17:30, устанавливаем на 10:30 следующего дня
    initialSelectedDate = setHours(setMinutes(new Date(), 30), 10);
  } else if (currentHour > 15) {
    initialSelectedDate = setHours(setMinutes(addDays(new Date(), 1), 30), 10);
  } else {
    // В противном случае, устанавливаем через 90 минут от текущего времени
    initialSelectedDate = new Date(currentDate.getTime() + 90 * 60 * 1000);
  }

  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const [initialBtn, setInitialBtn] = useState(false);
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button type="button" className={stl.custom} onClick={onClick} ref={ref}>
      {initialBtn ? value : "Выберите время и дату"}
    </button>
  ));

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setInitialBtn(true);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    // const formattedTime = new Intl.DateTimeFormat("ru-RU", options).format(
    //   date
    // );
    // onTimeChange && onTimeChange(formattedTime);
    onTimeChange && onTimeChange(date);
    // console.log(date, "ВРЕМЯЯЯЯЯЯЯЯЯЯ");
  };

  const filterTime = (time) => {
    // console.log(time);
    const currentDate = new Date();
    const timebomb = new Date(currentDate.getTime() + 90 * 60 * 1000);
    const selectedDate = new Date(time);

    // // Исключаем время для выбора с 0 до 9 и с 21 до 0
    // const currentHour = selectedDate.getHours();
    // if (currentHour < 9 || currentHour >= 18) {
    //   return false;
    // }

    return timebomb < selectedDate.getTime();
  };

  const includeDateIntervals = [{ start: Date(), end: addDays(new Date(), 2) }];

  // Преобразовать интервалы дат в массив отдельных дат
  const includedDates = includeDateIntervals.flatMap((interval) =>
    eachDayOfInterval(interval)
  );

  const tomorrowMinTime = setHours(setMinutes(addDays(new Date(), 1), 30), 10);
  const dayAfterTomorrowMinTime = setHours(
    setMinutes(addDays(new Date(), 2), 0),
    9
  );

  return (
    // <div className={stl.wrapper}>
    <div className={stl.wrap}>
      {/* <label>
        {deliveryOption === "Доставка"
          ? "Дата доставки: "
          : "Дата самовывоза: "}
      </label> */}
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        showTimeSelect
        filterTime={filterTime}
        // filterDate={filterDate}
        includeDateIntervals={[
          { start: subDays(new Date(), 1), end: addDays(new Date(), 2) },
        ]}
        disabledKeyboardNavigation
        // placeholderText="Выберите время"
        highlightDates={includedDates}
        // minTime={setHours(setMinutes(new Date(), 0), 9)}
        minTime={
          selectedDate &&
          selectedDate.getDate() === addDays(new Date(), 0).getDate()
            ? tomorrowMinTime
            : dayAfterTomorrowMinTime
        }
        maxTime={setHours(setMinutes(new Date(), 30), 17)}
        timeFormat="HH:mm"
        timeIntervals={30}
        dateFormat="MMMM d, yyyy HH:mm"
        timeCaption="Время" // Устанавливаем текст для времени
        // withPortal
        customInput={<ExampleCustomInput />}
        // inline
        // readOnly={true}
        // className={stl.customDatePicker}
      />
    </div>
  );
};

export default DeliveryDateTime;
