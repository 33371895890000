import { createSlice } from "@reduxjs/toolkit";
import { loadCities } from "../../../http/cityApi";

const initialState = {
  cities: [],
  loading: false,
  error: null,
  selectedCity: { id: "55", url: "moscow", name: "москва" },
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    toggleCities: (state, action) => {
      state.showOption = action.payload;
    },
    setSelectedCity: (state, action) => {
      state.selectedCity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
      })
      .addCase(loadCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { setSelectedCity } = citySlice.actions;
export const { toggleCities } = citySlice.actions;

export default citySlice.reducer;
