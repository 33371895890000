import React, { useState } from "react";
import stl from "./delivery.module.scss";
import Zone from "./Zone/Zone";
import Tarifs from "./Tarifs/Tarifs";

const Delivery = () => {
  return (
    <div className={stl.wrapper}>
      <Tarifs />
      {/* <Zone /> */}
    </div>
  );
};

export default Delivery;
