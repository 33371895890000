import React, { useState } from "react";
import stl from "./pluscat.module.scss";
import { addSubCategory } from "../../../app/http/typeApi";
import { useDispatch } from "react-redux";

const PlusSubCategory = ({ parentId }) => {
  const [data, setData] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    console.log(parentId);
    if (data === "") {
      e.preventDefault();
      //   onBlur();
      alert("пусто");
    } else {
      e.preventDefault();
      dispatch(
        addSubCategory({
          name: data,
          parentId: parentId,
        })
      );
      setData("");
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          className={stl.inp_cat}
          type="text"
          value={data}
          onChange={(e) => setData(e.target.value)}
        />

        <button className={stl.add}>+ подкатегория</button>
        {/* <SvgAdd className={stl.svgadd} /> */}
      </form>
    </div>
  );
};

export default PlusSubCategory;
