import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTotalPrice } from "../../app/redux/store/totalPriceStore/totalPriceSlice";
import {
  setColor,
  setModel,
} from "../../app/redux/store/ImageConfigure/ImageConfigureSlice";
import { loadImageByOption } from "../../app/http/ImageConfigureApi";
import { selectColorOptions } from "../../app/redux/store/optionSelectStore/optionSelectSlice";
import stl from "./scss/OptionsDeviceFrontColors.module.scss";

const OptionsDeviceFrontColors = () => {
  const dispatch = useDispatch();
  // const optionsSelect = useSelector((state) => {
  //   return state.optionsSelect?.options?.optionLists?.[0]?.options || [];
  // });
  const optionsSelect = useSelector(
    (state) => state.optionsSelect?.options?.optionLists
  );
  // console.log(optionsSelect, "tetttttttt");

  // Состояние для отслеживания выбранной опции
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClick = (option) => {
    // Обновляем выбранную опцию
    dispatch(setColor(option.id));
    setSelectedOption(option);
    dispatch(
      updateTotalPrice({
        price: option.priceChange,
        nameOptionValue: option.name,
        name: optionsSelect[0]?.name,
      })
    );
    // console.log(optionsSelect, "id опции");
    // dispatch(loadImageByOption(selectedOptions));
  };
  // useEffect(() => {
  //   if (optionsSelect.length > 0 && selectedOption === null) {
  //     // setSelectedOption(optionsSelect[0]?.options[0]);
  //     // dispatch(setColor(optionsSelect[0]?.options[0]?.id));
  //     // dispatch(
  //     //   updateTotalPrice({
  //     //     price: optionsSelect[0]?.options[0]?.priceChange,
  //     //     name: optionsSelect[0]?.name,
  //     //     nameOptionValue: optionsSelect[0]?.options[0]?.name,
  //     //   })
  //     // );
  //     // console.log(optionsSelect[0]?.options[0], "TEST набора опций выбранный");
  //   }
  // }, [optionsSelect, selectedOption]);
  // useEffect(() => {
  //   dispatch(loadImageByOption(selectedOptions));
  // }, [selectedOptions]);

  return (
    <div>
      {optionsSelect && optionsSelect.length > 0 && (
        <p className={stl.h4}>Цвет упаковки</p>
      )}
      <ul className={stl.wrapper}>
        {optionsSelect &&
          optionsSelect.length > 0 &&
          optionsSelect[0]?.options
            .filter((option) => option.exist === true)
            .map((option) => (
              <div
                key={option.id}
                className={`${stl.images} ${
                  selectedOption?.id === option.id ? stl.selected : ""
                }`}
                style={{ backgroundColor: option.color }}
                onClick={() => handleClick(option)}
                title={option.name}>
                {/* <img
                  key={option.id}
                  onClick={() => handleClick(option)}
                  title={option.name}
                  src={
                    process.env.REACT_APP_API_URL +
                    "options/" +
                    option?.imageUrl
                  }
                  alt={option.name}
                /> */}
              </div>
            ))}
      </ul>
    </div>
  );
};

export default OptionsDeviceFrontColors;
