import React from "react";
import stl from "./modal.module.scss";

const Modal = ({ children, visible, setVisible, onClickOutside }) => {
  if (!visible) return null;
  const rootCL = `${stl.modal} ${visible ? stl.active : ""}`;

  const handleOutsideClick = () => {
    if (onClickOutside) {
      onClickOutside();
    } else {
      setVisible(false);
    }
  };

  return (
    <div className={rootCL} onClick={handleOutsideClick}>
      <div className={stl.modalContent} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
