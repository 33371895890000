import { useSelector } from "react-redux";
import AdminPanel from "../../pages/admin/AdminPanel";
import Baskets from "../../pages/products/baskets/Baskets";
import Berry from "../../pages/products/berry/Berry";
import Bouquets from "../../pages/products/bouquets/Bouquets";
import Boxes from "../../pages/products/boxes/Boxes";
import Login from "../../widgets/auth-form/Login";
import Registration from "../../widgets/auth-form/Registration";
import {
  ADMIN_ROUTE,
  BASKETS,
  BERRY,
  BOUQUET,
  BOXES,
  DEVICE_ROUTE,
  GLAVNAYA,
  LOGIN_ROUTE,
  REGISTRATION_ROUTE,
} from "./utils/consts";
import DeviceItem from "../../entities/product/DeviceItem";
import Glavnaya from "../../shared/ui/glavnaya/Glavnaya";
import SliderAdmin from "../../pages/admin/content/Outlet/SliderAdmin";

// разделение прав между пользователем и админом

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: AdminPanel,
    children: [
      {
        path: "slider",
        Component: SliderAdmin,
      },
    ],
  },
];

export const publicRoutes = [
  {
    path: BOUQUET,
    Component: Bouquets,
  },
  // {
  //   path: REGISTRATION_ROUTE,
  //   Component: Registration,
  // },
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
  {
    path: DEVICE_ROUTE,
    Component: DeviceItem,
  },
];
