import React from "react";
import { Outlet, useParams } from "react-router-dom";
import Content from "./content";
import stl from "./index.module.scss";
import Left from "./navbar";
// import Right from './right/Right';

const AdminPanel = () => {
  return (
    <div className={stl.wrapper}>
      <Left />
      <Outlet />
      {/* <Content /> */}
      {/* <Right ытмтвыпт /> */}
      {/* Админ */}
    </div>
  );
};

export default AdminPanel;
