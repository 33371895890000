import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const createZone = createAsyncThunk(
  "@@delivery/create-zone",
  async (zone, { rejectWithValue }) => {
    try {
      console.log(zone);
      const response = await $host.post("api/delivery", zone); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadJson = createAsyncThunk(
  "@@delivery/load-json",
  async (zone, { rejectWithValue }) => {
    try {
      console.log(zone);
      const response = await $host.post("api/delivery/uploadFile", zone); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createTarif = createAsyncThunk(
  "@@delivery/create-tarif",
  async (tarif, { rejectWithValue }) => {
    try {
      // console.log(zone);
      const response = await $host.post("api/delivery/tarif", tarif); // Замените на ваш путь для получения городов
      console.log(response, "что у нас по тарифам пришло с сервера???");
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const editTarif = createAsyncThunk(
  "@@delivery/edit-tarif",
  async (editedTarifs, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/delivery/tarif`, editedTarifs);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteTarif = createAsyncThunk(
  "@@delivery/delete-tarif",
  async (tarif, { rejectWithValue }) => {
    try {
      const response = await $host.delete(`api/delivery/tarif/${tarif}`);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const editZone = createAsyncThunk(
  "@@delivery/edit-zone",
  async (editZone, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/delivery/zone`, editZone);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadZone = createAsyncThunk(
  "@@delivery/load-zone",
  async (_, { rejectWithValue }) => {
    try {
      const response = await $host.get("api/delivery/zone"); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadTarifFromZone = createAsyncThunk(
  "@@delivery/load-tarif-from-zone",
  async (data, { rejectWithValue }) => {
    try {
      const dateAndZone = JSON.stringify(data);
      const response = await $host.get(`api/delivery/tarifzone/${dateAndZone}`); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const loadTarif = createAsyncThunk(
  "@@delivery/load-tarif",
  async (cat, { rejectWithValue }) => {
    try {
      const response = await $host.get(`api/delivery/tarifs/${cat}`); // Замените на ваш путь для получения городов
      return response.data; // Предполагается, что города находятся в свойстве data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);
