import { createSlice } from "@reduxjs/toolkit";

import {
  createDeviceOption,
  loadDeviceOptionId,
  loadOptionsSelect,
  loadOptionsSelectDevice,
} from "../../../http/optionSelectApi";
import { loadOptions } from "../../../http/optionApi";
import { createSelector } from "reselect";

// const initialState = {
//   status: "idle",
//   showOptionr: false,
//   list: [],
//   uploadedImage: null,
// };
const OptionSelectSlice = createSlice({
  name: "optionsSelect",
  // initialState,
  initialState: {
    showOption: false,
    list: {}, // опции для админки
    options: {}, // опции для пользовательского интерфейса
    uploadedImage: null,
    // message: null
  },
  reducers: {
    toggleOption: (state, action) => {
      state.showOption = action.payload;
    },
    setImage: (state, action) => {
      state.uploadedImage = action.payload;
      // state.list.push(action.payload);
      // state.list = action.payload.data;
    },
    clearImage: (state) => {
      state.uploadedImage = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createDeviceOption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createDeviceOption.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createDeviceOption.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.list = {
        //   standaloneOptions: action.payload.data.optionals || [],
        //   optionLists: action.payload.data.optionalLists || [],
        // };
      })
      .addCase(loadOptionsSelect.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadOptionsSelect.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadOptionsSelect.fulfilled, (state, action) => {
        state.status = "received";
        state.list = action.payload.data;
      })
      .addCase(loadOptionsSelectDevice.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadOptionsSelectDevice.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadOptionsSelectDevice.fulfilled, (state, action) => {
        state.status = "received";
        state.options = action.payload.data.dataOptions;
      })
      .addCase(loadDeviceOptionId.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loadDeviceOptionId.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload || action.meta.error;
      })
      .addCase(loadDeviceOptionId.fulfilled, (state, action) => {
        state.status = "received";
        state.options = action.payload.dataOptions;
      });
  },
});

// export const { setImage, clearImage } = OptionSelectSlice.actions;
// export const { toggleOption } = OptionSelectSlice.actions;
export const selectColorOptionsTrue = (state) => {
  const optionLists = state.optionsSelect.options?.optionLists || [];
  return optionLists.filter((option) => option.type === "Цвета");
};
export const selectColorOptions = (state) => {
  const optionLists = state.optionsSelect.options?.optionLists || [];
  return optionLists.filter((option) => option.type === "Цвета");
};
export const selectModelOptions = (state) => {
  const optionLists = state.optionsSelect.options?.optionLists || [];
  return optionLists.filter((option) => option.type === "Модели");
};
// export const selectAdvancedOptions = (state) => {
//   const optionLists = state.optionsSelect.options?.optionLists || [];
//   return optionLists.filter((option) => option.type === "");
// };
const selectOptionsState = (state) => state.optionsSelect;
// Мемоизированный селектор для фильтрации options
export const selectAdvancedOptions = createSelector(
  [selectOptionsState],
  (optionsSelect) => {
    const optionLists = optionsSelect.options?.optionLists || [];
    return optionLists.filter((option) => option.type === "");
  }
);
export default OptionSelectSlice.reducer;
