import React, { useState } from "react";
import stl from "./yon.module.scss";

// confirmation dialog
const YON = ({ setVisible, del, id, text }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const yes = () => {
    del(id);
    setVisible(false);
  };
  const no = () => {
    setVisible(false);
  };

  return (
    <div className={stl.wrapper}>
      {!checked ? (
        <div className={stl.redpre}>Удалить</div>
      ) : (
        <div className={stl.red} onClick={yes}>
          Удалить
        </div>
      )}

      <div className={stl.blue} onClick={no}>
        Отменить
      </div>
      <div className={stl.text}>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        {text}
      </div>
    </div>
  );
};

export default YON;
