import { createAsyncThunk } from "@reduxjs/toolkit";
import { $host } from "./index";

export const addOption = createAsyncThunk(
  "@@options/create-option",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await $host.post("api/options", formData);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loadOptions = createAsyncThunk("@@option/load-options", () =>
  $host.get("api/options")
);

export const updateOption = createAsyncThunk(
  "@@options/update-option",
  async ({ id, formDataToSubmit }, { rejectWithValue }) => {
    try {
      const response = await $host.patch(`api/options/${id}`, formDataToSubmit);
      console.log(response);
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteOption = createAsyncThunk(
  "@@options/delete-option",
  async (id) => {
    const res = await $host.delete(`api/options/${id}`);
    console.log(res.data);
    return res.data;
  }
);

// дальше нужно править...
