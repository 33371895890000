import React, { useEffect, useState } from "react";
import Modal from "../../../shared/ui/modal/Modal";
import OptionModal from "./OptionModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteOption, loadOptions } from "../../../app/http/optionApi";
import style from "./options.module.scss";
import OptionModalList from "./OptionModalList";
import YON from "../../../shared/ui/yesorno/Yon";
import OptionModalEdit from "./OptionModalEdit";
import {
  delOptionListVariable,
  loadOptionList,
} from "../../../app/http/optionListApi";

const Options = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modalActivedel, setModalActivedel] = useState(false);
  const [modalType, setModalType] = useState(null);

  const [modalActiveEdit, setModalActiveEdit] = useState(false);
  const [openOptions, setOpenOptions] = useState({});
  const [modalListActive, setModalListActive] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [editId, setEditId] = useState("");
  const [delId, setDelID] = useState("");
  const dispatch = useDispatch();
  const options = useSelector((state) => state.option.list);
  const optionList = useSelector((state) => state.optionList.list);
  // console.log(optionList);

  const addOption = () => {
    setModalActive(true);
  };
  const addOptionList = () => {
    setModalType("list");
    setModalListActive(true); // Открываем модальное окно для списка опций
  };
  const addOptionListVariable = () => {
    setModalType("variable");
    setModalListActive(true); // Открываем модальное окно для списка опций
  };

  const editOption = (id) => {
    setModalActiveEdit(true);
    setEditId(id);
  };
  const predelClick = (id) => {
    setModalActivedel(true);
    setDelID(id);
  };
  const delOption = (optionId) => {
    // console.log(optionId);
    dispatch(deleteOption(optionId)); // Передаем ID опции для удаления
  };

  const handleDeleteOption = (id) => {
    // удаляем вариацию
    console.log(id, "удаляем вариацию");
    dispatch(delOptionListVariable(id));
    // dispatch({ type: "DELETE_OPTION", payload: id });
  };

  // Функция для переключения состояния открытой/закрытой опции
  const toggleOption = (optionId) => {
    console.log(optionId);
    setOpenOptions((prevOpenOptions) => ({
      ...prevOpenOptions,
      [optionId]: !prevOpenOptions[optionId],
    }));

    // Если опция была раскрыта, устанавливаем ее ID в selectedOptionId
    if (!openOptions[optionId]) {
      setSelectedOptionId(optionId);
    } else {
      setSelectedOptionId(null);
    }
  };

  useEffect(() => {
    dispatch(loadOptions());
  }, []);

  useEffect(() => {
    dispatch(loadOptionList());
  }, []);

  return (
    <div className={style.optionsContainer}>
      <div className={style.optionsList}>
        <Modal visible={modalActive} setVisible={setModalActive}>
          <OptionModal setVisible={setModalActive} />
        </Modal>
        <Modal visible={modalActiveEdit} setVisible={setModalActiveEdit}>
          <OptionModalEdit
            setVisible={setModalActiveEdit}
            id={editId}
            options={options}
          />
        </Modal>
        <p>Опции</p>
        <button onClick={addOption}>Добавить опцию</button>
        <ul>
          {options.map((option, index) => (
            <li key={option.id}>
              <div>
                <p>
                  {option.name} - {option.about}
                </p>
                {openOptions[option.id] && option.imageUrl && (
                  <img
                    className={style.imgprev}
                    src={
                      process.env.REACT_APP_API_URL +
                      "options/" +
                      option.imageUrl
                    }
                    alt="Изображение"
                  />
                )}
                {openOptions[option.id] && (
                  <table>
                    <tbody>
                      <tr>
                        <td>Название:</td>
                        <td>{option.name}</td>
                      </tr>
                      <tr>
                        <td>Описание:</td>
                        <td>{option.tarea}</td>
                      </tr>
                      <tr>
                        <td>Доступность для покупателей:</td>
                        {option.exist === true && <td>Да</td>}
                        {option.exist === false && <td>нет</td>}
                      </tr>

                      <tr>
                        <td>Прайсовая наценка:</td>
                        <td>{option.priceChange}</td>
                      </tr>
                      <tr>
                        <td>Тип наценки:</td>
                        <td>{option.type}</td>
                      </tr>
                      <tr>
                        <td>Тип кнопки:</td>
                        <td>{option.OptionalType}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
              <button onClick={() => toggleOption(option.id)}>
                {openOptions[option.id] ? "Скрыть" : "Показать детали"}
              </button>
              {selectedOptionId === option.id && (
                <button
                  className={style.edit}
                  onClick={() => editOption(option.id)}>
                  Редактировать
                </button>
              )}
              {selectedOptionId === option.id && (
                <button
                  className={style.delete}
                  onClick={() => predelClick(option.id)}>
                  Удалить
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={style.addListButton}>
        <button onClick={addOptionList}>Добавить список</button>
        <ul>
          {optionList
            .filter((option) => !option.imageUrl) // Отфильтровать элементы с существующим imageUrl
            .map((option) => (
              <li key={option.id}>
                <span>{option.name}</span>
                <button onClick={() => handleDeleteOption(option.id)}>
                  Удалить
                </button>
                {/* <ul>
                  {option?.optionsList &&
                    option.optionsList.map((item) => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                </ul> */}
              </li>
            ))}
        </ul>
      </div>
      <hr />
      <div className={style.addListButton}>
        <button onClick={addOptionListVariable}>Добавить вариации опций</button>
        <ul>
          {optionList
            .filter((option) => option.imageUrl) // Отфильтровать элементы с существующим imageUrl
            .map((option) => (
              <li key={option.id}>
                <span>{option.name}</span>
              </li>
            ))}
        </ul>
      </div>
      <Modal visible={modalActivedel} setVisible={setModalActivedel}>
        <YON
          text={
            "Внимание, при удалении будут затронуты взаимосвязи категорий и товаров, где используется данная опция, смотрите внимательно"
          }
          del={delOption}
          id={delId}
          setVisible={setModalActivedel}
        />
      </Modal>
      {modalType === "list" ? (
        <Modal visible={modalListActive} setVisible={setModalListActive}>
          <OptionModalList setVisible={setModalListActive} />
        </Modal>
      ) : modalType === "variable" ? (
        <Modal visible={modalListActive} setVisible={setModalListActive}>
          <OptionModalList
            setVisible={setModalListActive}
            variable={"variable"}
          />
        </Modal>
      ) : null}
    </div>
  );
};

export default Options;
