import React from "react";
import { ReactComponent as SvgTime } from "./svg/time.svg";
import stl from "./dostavka.module.scss";

const Dostavka = () => {
  return (
    <div className={stl.wrapper}>
      <p>Условия доставки</p>
      <p>Данный тариф только для центральных районов Саратова и Энгельса</p>
      <div className={stl.block}>
        <div className={stl.left}>
          <SvgTime className={stl.svg} />
        </div>
        <div className={stl.right}>
          <div>
            <p>
              <ul>
                <li>Ранняя с 7:30 до 9:30 350₽</li>
                <li>Дневная с 9:30 до 10:30 290₽ с 10:30 до 16:00 250₽</li>
                <li>Вечерняя с 16:00 до 20:00 350₽</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <hr className={stl.hr} />
      <p>
        Оформление заказа с 17:00 до 17:30 означает доставку на следующий день,
        начиная с 8:00
      </p>
      <hr className={stl.hr} />
      <p>
        Вечернее и ночное оформление заказа с 17:30 до 9:00 означает доставку с
        10:30 до 20:00
      </p>
      <hr className={stl.hr} />
    </div>
  );
};

export default Dostavka;
