import { createSlice } from "@reduxjs/toolkit";
import {
  UniqueLoadH,
  UniqueLoadWH,
  addPrice,
  addPriceMass,
  loadPrice,
  updatePriceMass,
} from "../../../http/priceApi";
import { createSelector } from "reselect";

const initialState = {
  loadedPricesById: {},
  addedPrices: [],
  uniqueWH: {},
  status: "idle",
  statusPrice: "idle",
  error: null,
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPrice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPrice.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addPrice.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addedPrices = action.payload;
      })
      .addCase(updatePriceMass.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updatePriceMass.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updatePriceMass.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.addedPrices = action.payload;
      })
      .addCase(addPriceMass.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPriceMass.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addPriceMass.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.addedPrices = action.payload;
      })
      .addCase(loadPrice.pending, (state) => {
        state.statusPrice = "loading";
      })
      .addCase(loadPrice.rejected, (state, action) => {
        state.statusPrice = "failed";
        state.error = action.error.message;
      })
      .addCase(loadPrice.fulfilled, (state, action) => {
        state.statusPrice = "succeeded";
        // state.loadedPricesById = {};
        const { deviceId, prices } = action.payload;
        // state.loadedPricesById[deviceId] = prices;
        if (prices && prices.length > 0)
          state.loadedPricesById[deviceId] = prices;
      })
      .addCase(UniqueLoadWH.pending, (state) => {
        state.status = "loading";
      })
      .addCase(UniqueLoadWH.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(UniqueLoadWH.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.uniqueWH = action.payload;
      })
      .addCase(UniqueLoadH.pending, (state) => {
        state.status = "loading";
      })
      .addCase(UniqueLoadH.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(UniqueLoadH.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.uniqueWH.height = action.payload.height;
      });
  },
});

// Простой селектор для извлечения состояния цен
export const selectPricesState = (state) => state.price;

// Мемоизированный селектор для извлечения цен по ID устройства
export const selectLoadedPricesById = createSelector(
  [selectPricesState, (state, deviceId) => deviceId],
  (priceState, deviceId) => priceState.loadedPricesById[deviceId] || []
);

// export const selectLoadedPricesById = (state, deviceId) => {
//   return state.price.loadedPricesById[deviceId] || [];
// };

export const { resetStatus } = priceSlice.actions;

export default priceSlice.reducer;
