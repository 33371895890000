import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllOrders } from "../../../app/http/orderApi";
import stl from "./orders.module.scss";

const Orders = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.orders);

  useEffect(() => {
    dispatch(loadAllOrders());
  }, []);

  // Функция для форматирования времени без секунд
  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return dateTime.toLocaleDateString("ru-RU", options);
  };

  return (
    <div>
      <h1>Заказы</h1>
      <table className={stl.ordersTable}>
        <thead>
          <tr style={{ background: "orangered" }}>
            <th>номер заказа</th>
            <th>тип</th>
            <th>имя</th>
            <th>email</th>
            <th>телефон</th>
            <th>адрес</th>
            <th>открытка</th>
            <th>Стоимость доставки</th>
            <th>Время</th>
            <th>Стоимость заказа</th>
            <th>Состояние оплаты</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.deliveryOption}</td>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td>{item.phone}</td>
              <td>{item.address}</td>
              <td>{item.comments}</td>
              <td>{item.priceDelivery}</td>
              <td>{formatDateTime(item.deliveryTime)}</td>{" "}
              {/* Форматирование даты и времени */}
              <td>{item.orderPrice}</td>
              <td>{item.variablePay}</td>
              <td>{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
