import React from "react";
import stl from "./popup.module.scss";

const Popup = ({ children, visible, setVisible }) => {
  // if (!visible) return null;
  const rootCL = `${stl.modal} ${visible ? stl.active : ""}`;
  const rootContent = `${stl.modalContent} ${visible ? stl.active : ""}`;

  return (
    <div className={rootCL} onClick={() => setVisible(false)}>
      <div className={rootContent} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
