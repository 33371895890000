import React, { useEffect } from "react";
import stl from "./glavnaya.module.scss";
import SliderFront from "../../../pages/admin/slider/front/SliderFront";
import Carousel from "../../other/Carousel/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { selectAllSlider } from "../../../app/redux/store/sliderStore/sliderSlice";
import { loadSlides } from "../../../app/http/slideApi";
import {
  selectAllTypes,
  selectSelectedUrl,
  setSelectedId,
} from "../../../app/redux/store/typeStore/typeSlice";
import { Link } from "react-router-dom";
import { loadbyNavbar } from "../../../app/http/typeApi";

const Glavnaya = () => {
  const dispatch = useDispatch();
  const navcat = useSelector(selectAllTypes);
  const selectedUrl = useSelector(selectSelectedUrl);

  console.log(navcat, "списко категорий");

  const handleLinkClick = (id) => {
    setSelectedId(id);
  };
  const isCategoryActive = true;

  const categoryNav = navcat.map((type) => (
    <div className={stl.category} key={type.id}>
      <Link
        to={`/category/${type.id}`}
        onClick={() => handleLinkClick(type.id)}>
        {type.imageUrl && (
          <div className={stl.imageWrapper}>
            <img
              className={stl.typeimg}
              src={process.env.REACT_APP_API_URL + "category/" + type?.imageUrl}
              alt=""
            />
            <div className={stl.caption}>
              <h2 className={stl.captionText}>{type.name}</h2>
            </div>
          </div>
        )}
      </Link>
    </div>
  ));

  useEffect(() => {
    dispatch(loadbyNavbar());
  }, [selectedUrl]);

  return (
    <>
      <div className={stl.wrapper}>
        <SliderFront />
        {/* <Carousel images={imageload} currentImg={0} /> */}
      </div>
      <div className={stl.wrapperCategory}>{categoryNav}</div>

      {/* <div className={stl.wrapperCategory}>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
        <div className={stl.category}>Ворота</div>
    </div> */}
      <hr />

      <p className={stl.h4}>Цветут 64</p>
      <div className={stl.text}>
        Добро пожаловать в наш мир цветов и красоты! Мы рады приветствовать вас
        на нашем уникальном сайте, посвященном искусству цветоведения. Здесь вы
        найдете нежные букеты, изысканные цветочные композиции и удивительные
        цветочные боксы, которые подарят радость и вдохновение. Наши цветы – это
        не просто растения, это источник эмоций, символы любви и заботы. Мы
        тщательно подбираем каждый цветок, чтобы создавать уникальные
        композиции, способные выразить ваши чувства и делать каждое событие
        незабываемым. Не важно, нужны ли вам цветы для особенного случая,
        подарка или просто для того, чтобы порадовать себя или близких. Мы
        здесь, чтобы помочь вам выбрать идеальные цветы и оформить заказ с
        удовольствием. Погрузитесь в мир красок и ароматов вместе с нами и
        сделайте свой выбор из нашего разнообразного ассортимента. Мы
        гарантируем высокое качество цветов и отличный сервис. Давайте создадим
        красоту вместе!
      </div>
    </>
  );
  //маршруты доставки
};

export default Glavnaya;
