import React from "react";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      {/* <div className={styles.content}> */}
      <Link to="/" className={styles.bybtn}>
        О компании
      </Link>
      <Link to="/dostavka" className={styles.bybtn}>
        О доставке
      </Link>
      {/* <p>Адреса магазинов</p> */}

      <Link to="/contacts" className={styles.bybtn}>
        Контакты
      </Link>
      {/* <p>Правила хранения цветов</p> */}
      {/* <p>Правила доставки и качества товаров</p> */}
      {/* </div> */}
    </footer>
  );
};

export default Footer;
