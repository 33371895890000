import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSumPrice,
  setBasedPrice,
  setLimitSquare,
} from "../../../app/redux/store/totalPriceStore/totalPriceSlice";
import stl from "./priceSelector.module.scss";
import { useSpring, animated } from "react-spring";
import { UniqueLoadH, UniqueLoadWH } from "../../../app/http/priceApi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OptionsDeviceFrontColors from "../../deviceOptionsFront/OptionsDeviceFrontColors";
import { Checkbox, SvgIcon, TextField } from "@mui/material";
// import { pink } from "@mui/material/colors";
import HeightIcon from "@mui/icons-material/Height";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";

const PriceSelector = ({
  id,
  price,
  name,
  pricechecked,
  dimensions,
  priceboxes,
  PriceBox,
}) => {
  // console.log(id, price, prices, "ПРАЙС!!!!!!!!!!!!!!");
  const dispatch = useDispatch();
  const prices = useSelector((state) => state.price?.loadedPricesById[id]);
  const aloneDevice = useSelector((state) => state.device?.selectedDevice);
  // const [width, setWidth] = useState("");
  // const [height, setHeight] = useState("");

  const uniqueWH = useSelector((state) => state.price.uniqueWH);
  const [selectedWidth, setSelectedWidth] = useState(
    uniqueWH.width ? parseInt(uniqueWH?.width[0]) : 0
  );
  const [selectedHeight, setSelectedHeight] = useState(
    uniqueWH.height ? parseInt(uniqueWH?.height[0]) : 0
  );

  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleManualInputToggle = () => {
    setChecked(!checked);
  };
  const handleBoxPrice = (e) => {
    const selectedPrice = e.target.value;
    setValue(selectedPrice);
    setCount(selectedPrice);
    // console.log(e.target.value, "что у нас с айдишником?");
    const selectedEntry = Object.values(PriceBox).find(
      (entry) => parseInt(entry.price, 10) === selectedPrice
    );
    // if (selectedEntry) {
    //   console.log(selectedEntry.quantity, "что у нас с айдишником?");
    // }
    dispatch(
      setBasedPrice({
        price: parseInt(e.target.value),
        idProduct: id,
        dimension: {
          height: 0,
          qnt: selectedEntry?.quantity,
        },
      })
    );
  };

  // console.log(height, "что там в массиве");

  // const [originalWidth, setOriginalWidth] = useState("");
  // const [originalHeight, setOriginalHeight] = useState("");
  const sumPrice = useSelector(selectSumPrice);
  const [count, setCount] = useState(sumPrice ? sumPrice : 0);
  const priceSpring = useSpring({
    number: count,
    from: { number: 0 },
  });
  // const [selectedPrice, setSelectedPrice] = useState(null);

  // const roundToNearest5 = (value) => {
  //   return Math.ceil(value / 10) * 10;
  // };

  // const handleWidthBlur = () => {
  //   setOriginalWidth(roundToNearest5(originalWidth));
  // };

  // const handleHeightBlur = () => {
  //   setOriginalHeight(roundToNearest5(originalHeight));
  // };

  // const handleWidthChange = (event) => {
  //   // setOriginalWidth(event.target.value);
  //   setWidth(Math.ceil(+event.target.value / 100) * 100);
  // };
  const handleManualInputChange = (event) => {
    const inputValue = parseInt(event.target.value);
    const closestHeight = findClosestValueInArray(inputValue, uniqueWH.height);

    if (closestHeight !== null) {
      const matchingPrice = prices.find(
        (price) =>
          price.width === parseInt(selectedWidth) &&
          price.height === closestHeight
      );

      if (matchingPrice) {
        const calculatedPrice = Math.round(
          (matchingPrice.price / closestHeight) * inputValue
        );

        dispatch(
          setBasedPrice({
            price: calculatedPrice,
            idProduct: id,
            // dimension: {
            //   height: selectedWidth,
            //   qnt: selectedHeight,
            // },
          })
        );
        // Дополнительный расчет или обработка, если необходимо
      } else {
        alert("Цена не найдена для указанной высоты");
      }
    } else {
      alert("Превышает максимальное значение цветов в букете");
    }
  };

  const findClosestValueInArray = (target, array) => {
    if (target > array[array?.length - 1] || target < 1) {
      return null;
    } else {
      let closest = null;

      for (let i = 0; i < array.length; i++) {
        if (array[i] <= target) {
          closest = array[i];
        } else {
          break; // прерываем цикл, так как массив отсортирован
        }
      }

      // Добавляем дополнительную проверку, чтобы ограничиться только значениями массива
      if (closest !== null) {
        return closest;
      }
    }
  };
  //первоначальная установка минимальной цены по минимальным размерам
  useEffect(() => {
    if (prices && prices.length > 0) {
      // setWidth(prices);
      // setHeight(prices[0].height);
      // dispatch(setBasedPrice(prices[0].price));
      dispatch(setBasedPrice({ price: prices[0]?.price, idProduct: id }));
    }
  }, [prices]);
  useEffect(() => {
    if (price && price > 0) {
      dispatch(
        setBasedPrice({
          price: price,
          dimension: {
            height: aloneDevice.height,
            qnt: aloneDevice.qnt,
          },
          idProduct: id,
        })
      );
    }
  }, [price]);
  useEffect(() => {
    if (priceboxes && PriceBox?.s?.price) {
      setCount(parseInt(PriceBox?.s?.price));
      setValue(PriceBox?.s?.price);
      dispatch(
        setBasedPrice({
          price: parseInt(PriceBox?.s?.price),
          idProduct: id,
          dimension: {
            height: 0,
            qnt: PriceBox?.s?.quantity,
          },
        })
      );
    }
  }, [PriceBox, id]);

  // useEffect(() => {
  //   // Проверка и установка значений после загрузки
  //   if (uniqueWH.width && !selectedWidth) {
  //     setSelectedWidth(parseInt(uniqueWH.width[0]));
  //   }

  //   if (uniqueWH.height && !selectedHeight) {
  //     setSelectedHeight(parseInt(uniqueWH.height[0]));
  //   }
  // }, [uniqueWH, selectedWidth, selectedHeight]);
  useEffect(() => {
    const numericWidth = parseInt(selectedWidth);
    const numericHeight = parseInt(selectedHeight);
    if (numericWidth > 0 && numericHeight > 0 && prices && prices.length > 0) {
      const selectedPrice = prices.find(
        (price) =>
          price.width === numericWidth && price.height === numericHeight
      );

      // console.log(selectedPrice, "эпроверка цены!!!!!!!!!!!!!!!!!!!!!");
      if (typeof selectedPrice !== "undefined") {
        // console.log(
        //   selectedWidth,
        //   selectedHeight,
        //   "какое тут значение????????????????"
        // );
        dispatch(
          setBasedPrice({
            price: selectedPrice?.price,
            dimension: {
              height: numericWidth,
              qnt: numericHeight,
            },
            idProduct: id,
          })
        );
      }
    }
    // console.log(selectedPrice, "TEST!!! PRICES!!!!!!!!!!");
  }, [selectedWidth, selectedHeight]);
  useEffect(() => {
    if (sumPrice) {
      // Анимировать изменение значения цены

      setCount(sumPrice);
    }
  }, [sumPrice]);
  useEffect(() => {
    if (prices && prices.length > 0) {
      dispatch(UniqueLoadWH(id));
    }
  }, [prices]);

  useEffect(() => {
    if (prices && prices.length > 0) {
      setSelectedWidth(prices[0].width || 0);
      setSelectedHeight(prices[0].height || 0);
    }
  }, [prices]);
  useEffect(() => {
    if (selectedWidth) {
      dispatch(UniqueLoadH({ id, selectedWidth }));
      console.log(selectedWidth, "какая высота стебляяяяяяяяяяяяяяяяяяяяяяя?");
    }
  }, [selectedWidth]);

  return (
    <div className={stl.wrapper}>
      <h1 className={stl.h1}>{name}</h1>
      <div className={stl.price}>
        {sumPrice !== null && (
          <div className={stl.priceText}>
            {/* Цена: */}
            <animated.div>
              {priceSpring.number.to(
                (val) => "Цена: " + val.toFixed(0) + " руб"
              )}
            </animated.div>
          </div>
        )}
      </div>
      {!pricechecked && !priceboxes && (
        <div>
          <div className={stl.checkbox}>
            {dimensions.height != 0 && (
              <>
                {/* <SvgIcon
                  component={HeightIcon}
                  fontSize="large"
                  inheritViewBox
                /> */}

                <p style={{ fontSize: "21px" }}>
                  Высота стебля: {dimensions.height} см
                </p>
              </>
            )}
          </div>
          {dimensions.height != 0 && (
            <div className={stl.checkbox}>
              {/* <SvgIcon
                fontSize="large"
                component={FilterVintageIcon}
                inheritViewBox
              /> */}
              <p style={{ fontSize: "21px" }}>
                Количество цветов: {dimensions.qnt} шт
              </p>
            </div>
          )}
        </div>
      )}

      {priceboxes && (
        <div className={stl.wrapperI}>
          <div className={stl.checkbox}>
            {/* <SvgIcon
              fontSize="large"
              component={FilterVintageIcon}
              inheritViewBox
            /> */}
            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Количество цветов:
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                // defaultValue={PriceBox?.s?.quantity}
                // onChange={handleChange}
                value={value || ""}
                // defaultValue={(PriceBox && PriceBox?.s?.price) || ""}
                // onChange={(e) => setCount(e.target.value)}
                onChange={handleBoxPrice}
                label="высота стебля">
                {PriceBox &&
                  Object.keys(PriceBox).map((key) => (
                    <MenuItem key={key} value={parseInt(PriceBox[key]?.price)}>
                      {capitalizeFirstLetter(key)}: {PriceBox[key]?.quantity} шт
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
      )}

      {pricechecked && (
        <div className={stl.wrapperI}>
          <div className={stl.checkbox}>
            {/* <SvgIcon component={HeightIcon} fontSize="large" inheritViewBox /> */}
            <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Высота стебля (см)
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                // defaultValue={""}
                value={selectedWidth || 40}
                // value={40 || ""}
                // onChange={handleChange}
                onChange={(e) => setSelectedWidth(e.target.value)}
                // onChange={handleSelectedPrice}

                label="высота стебля">
                {uniqueWH &&
                  uniqueWH.width &&
                  uniqueWH.width.map((width) => (
                    <MenuItem key={width} value={parseInt(width)}>
                      {width}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {!checked && (
            <div className={stl.checkbox}>
              {/* <SvgIcon
                fontSize="large"
                component={FilterVintageIcon}
                inheritViewBox
              /> */}

              <FormControl variant="standard" sx={{ m: 1, minWidth: 180 }}>
                <InputLabel id="demo-simple-select-standard-label">
                  Количество цветов:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  // value={age}
                  // onChange={handleChange}
                  defaultValue={1}
                  value={selectedHeight || 1}
                  onChange={(e) => setSelectedHeight(e.target.value)}
                  label="количество">
                  {uniqueWH.height &&
                    uniqueWH.height.map((height) => (
                      <MenuItem key={height} value={parseInt(height)}>
                        {height}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          {checked && (
            <div className={stl.checkbox}>
              {/* <SvgIcon
                fontSize="large"
                component={FilterVintageIcon}
                inheritViewBox
              /> */}
              <TextField
                id="outlined-basic"
                label="количество цветов"
                variant="outlined"
                type="number"
                placeholder="1"
                defaultValue={1}
                onBlur={handleManualInputChange}
                inputProps={{ min: 1 }}
              />{" "}
              {/* <button onClick={handleManualInputChange}>применить</button> */}
            </div>
            // <div>
            //   {/* <label htmlFor="manualInput">Задать вручную:</label> */}
            //   <input
            //     id="manualInput"
            //     className={stl.inputs}
            //     type="number"
            //     placeholder="0"
            //     // value={someValue}
            //     // onChange={handleHeightChange}
            //     onBlur={handleManualInputChange}
            //     // onBlur={handleManualInputBlur}
            //   />
            // </div>
          )}
          <div className={stl.checkbox}>
            <Checkbox
              label="Checkbox demo"
              checked={checked}
              onChange={handleManualInputToggle}
              color="success"
            />{" "}
            Задать вручную количество
          </div>

          {/* <label>
            <input
              type="checkbox"
              checked={checked}
              onChange={handleManualInputToggle}
            />
            Задать вручную
          </label>
          <label htmlFor="высота">Высота стебля(см)</label> */}
          {/* <input
          className={stl.inputs}
          type="number"
          placeholder="Высота стебля"
          // value={originalWidth}
          onChange={handleWidthChange}
          // onBlur={handleWidthBlur}
        /> */}
          {/* <select
            value={selectedWidth}
            className={stl.desc}
            onChange={(e) => setSelectedWidth(e.target.value)}>
            {uniqueWH.width &&
              uniqueWH.width.map((width) => (
                <option key={width} value={parseInt(width)}>
                  {width}
                </option>
              ))}
          </select>
          <label className={stl.leftdesc}>Количество цветков:</label>
          {!checked && (
            <select
              value={selectedHeight}
              className={stl.desc}
              onChange={(e) => setSelectedHeight(e.target.value)}>
              {uniqueWH.height &&
                uniqueWH.height.map((height) => (
                  <option key={height} value={parseInt(height)}>
                    {height}
                  </option>
                ))}
            </select>
          )} */}
          {/* <label htmlFor="количество">Количество цветков вручную</label> */}
          {/* <label>
            <input
              type="checkbox"
              checked={checked}
              onChange={handleManualInputToggle}
            />
            Задать вручную
          </label> */}

          {/* <OptionsDeviceFrontColors /> */}
          {/* <button className={stl.btn} onClick={handleManualInputChange}>
          Изменить размер
        </button> */}
        </div>
      )}
    </div>
  );
};

export default PriceSelector;
