import React from "react";

const ChildTest = ({ pru, dru }) => {
  const b = pru.Dima;
  const num = 19;
  const func = () => dru(num, b);
  func();

  return <div> {b}</div>;
};

export default ChildTest;
