// orderSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createOrder, loadAllOrders } from "../../../http/orderApi";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    status: "idle",
    data: null,
    error: null,
    orders: [],
    // showPayWidget: false, // Добавляем новое поле для отображения виджета оплаты
  },
  reducers: {
    toggleStatus: (state, action) => {
      const { statusInfo } = action.payload;
      state.status = statusInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        // state.status = "succeeded";
        state.data = action.payload.data;
        if (action.payload?.data?.confirmationToken?.status === "pending") {
          state.status = "pending";
        }
        state.error = null;
        // После успешного создания заказа, устанавливаем showPayWidget в true
        // state.showPayWidget = true;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status = "failed";
        state.data = null;
        state.error = action.payload; // payload здесь содержит информацию об ошибке
        // В случае ошибки, устанавливаем showPayWidget в false
        // state.showPayWidget = false;
      })
      .addCase(loadAllOrders.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadAllOrders.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload.data;
      })
      .addCase(loadAllOrders.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { toggleStatus } = orderSlice.actions; // Экспортируем новое действие
export const selectOrderStatus = (state) => state.order?.status;
export const selectOrderData = (state) => state.order.data?.confirmationToken;
export const selectOrderError = (state) => state.order.error;
// export const selectShowPayWidget = (state) => state.order.showPayWidget; // Новый селектор

export default orderSlice.reducer;
