import React, { useState } from "react";
import style from "./order.module.scss";
import { Link } from "react-router-dom";
import {
  addItemToCart,
  deleteItemFromCart,
  removeItemFromCart,
  selectAllItems,
  selectTotalPrice,
} from "../../app/redux/cartStore/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import OrderBy from "../orderby/OrderBy";
import { IoMdCloseCircle } from "react-icons/io";
import { CiCircleMinus, CiCirclePlus, CiCircleRemove } from "react-icons/ci";

const Order = ({ closeModal }) => {
  const dispatch = useDispatch();
  const allItems = useSelector(selectAllItems);
  const allPrice = useSelector(selectTotalPrice);

  const handleAddToCart = (item) => {
    dispatch(addItemToCart(item));
  };

  const handleRemoveItemFromCart = (item) => {
    dispatch(removeItemFromCart(item));
  };
  const handleRemoveFromCart = (item) => {
    dispatch(deleteItemFromCart(item));
  };

  const renderItems = () => {
    return allItems.map((item) => {
      if (item.quantity > 0) {
        return (
          <div key={item.price} className={style.item}>
            <img
              src={
                process.env.REACT_APP_API_URL +
                "images/products/" +
                item?.image[0]?.mini
              }
              className={style.img}
              alt="sdf"
            />
            <div className={style.row}>
              <div>{item.name}</div>
              {item.dimensions && (
                <div>
                  {item.dimensions.height != 0 && (
                    <div>высота цветка: {item.dimensions.height}см</div>
                  )}

                  <div>количество: {item.dimensions.qnt}шт</div>
                </div>
              )}
              <div>Цена: {item.price} руб</div>
              <div>Количество: {item.quantity}</div>
              <div>Итого {item.quantity * item.price} руб</div>
              <div>
                <CiCirclePlus
                  className={style.plus}
                  onClick={() => handleAddToCart(item)}
                />
                <CiCircleMinus
                  className={style.minus}
                  onClick={() => handleRemoveItemFromCart(item)}
                />
                <CiCircleRemove
                  className={style.remove}
                  onClick={() => handleRemoveFromCart(item)}
                />
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className={style.wrapper}>
      <IoMdCloseCircle className={style.close} onClick={closeModal} />
      <div className={style.order}>
        {/* <Link to="/checkout" className={style.bybtn} onClick={closeModal}>
          Оформить
        </Link> */}
        <div className={style.bybtn}>
          Приносим извинения. Временно не принимаем заказы на сайте. Скоро всё
          заработает с новым функционалом
        </div>
      </div>
      <div className={style.zakaz}> {renderItems()}</div>
      <div className={style.allprice}>Итого: {allPrice} руб</div>
    </div>
  );
};

export default Order;
